import Vue from 'vue'
import VueRouter from 'vue-router'
import { auth } from '../firebase'
import Dashboard from '../views/Dashboard.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },
  // {
  //   path:'/project/:projectId',
  //   name: 'Project',
  //   component: () =>import( /* webpackChunkName: "login" */ '../views/projects/ProjectLayout.vue'),
  //   meta:{
  //     requiresAuth: true,
  //     can
  //   },
  // },
  {
    path:'/projects',
    name: 'Projects',
    component: () =>import( /* webpackChunkName: "login" */ '../views/projects/Projects.vue'),
    meta:{
      requiresAuth: true
    },

  },
  {
    path: '/login',
    name: 'Login',
    component: () => import( /* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/verify-email',
    name: 'VerifyEmail',
    component: () => import( /* webpackChunkName: "login" */ '../views/auth/VerifyEmail.vue'),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import( /* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/completeSignup',
    name: 'CompleteSignup',
    component: () => import( /* webpackChunkName: "completeSignup" */ '../views/CompleteSignup.vue'),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import( /* webpackChunkName: "settings" */ '../views/settings/index.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/editor',
    name: 'editor',
    props: true,
    component: () => import(/* webpackChunkName: "editor" */'../views/session/Transcript.vue'),
    meta: {
      requiresAuth: true,
      isCollaborative: true,
    }
  },
  {
    path: '/search',
    name: 'search',
    props: true,
    component: () => import(/* webpackChunkName: "editor" */'../views/search/index.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/workspace/:id',
    name: 'workspace',
    props: true,
    component: () => import(/* webpackChunkName: "editor" */'../views/workspace/Workspace.vue'),
  },
  {
    path: '/workspace',
    name: 'workspaces',
    props: true,
    component: () => import(/* webpackChunkName: "editor" */'../views/workspace/Workspace.vue'),
  },
  {
    path: '/brewnote/editor/:id',
    name: 'brewnote editor',
    props: true,
    component: () => import(/* webpackChunkName: "editor" */'../views/brew-note/Transcript.vue'),
  },
  {
    // /search/screens -> /search?q=screens
    path: '/brewnote/editor',
    redirect: '/brewnote/editor/sample'
  },
  {
    path: '/brewnote',
    name: 'brewnote',
    props: true,
    component: () => import(/* webpackChunkName: "editor" */'../views/brew-note/Home.vue'),
  },
  {
    path:'/calendar',
    name:'calendar',
    props: true,
    component: () => import(/* webpackChunkName: "calendar"*/'../views/calendar/index.vue'),
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/share/session/:id',
    name: 'session_share',
    // props: true,
    component: () => import(/* webpackChunkName: "settings" */'../views/session/Transcript.vue'),
  },
  {
    path: '/summary/:projectId',
    name: 'summary',
    // props: true,
    component: () => import(/* webpackChunkName: "settings" */'../views/summary/index.vue'),
  },
  {
    path: '/tagboard',
    name: 'tagboard',
    // props: true,
    component: () => import(/* webpackChunkName: "tagboard" */'../views/Tagboard.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/highlights',
    name: 'highlights',
    // props: true,
    component: () => import(/* webpackChunkName: "settings" */'../views/Highlights.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/snippet/:id',
    name: 'snippet',
    // props: true,
    component: () => import(/* webpackChunkName: "settings" */'../views/Snippet.vue'),
  },
  {
    path: '/share/snippet/:id',
    name: 'snippet',
    // props: true,
    component: () => import(/* webpackChunkName: "settings" */'../views/Snippet.vue'),
  },
  {
    path: '/zoom_callback',
    name: 'Zoom Callback',
    component: () => import( /* webpackChunkName: "login" */ '../views/ZoomCallback.vue')
  },
  {
    path: '/notes/:id',
    name: 'Notetaking',
    component: () => import( /* webpackChunkName: "notetaking" */ '../views/Notetaking.vue')
  },
  {
    path: '/project/:projectId/sessions',
    name: 'Project Sessions',
    component: () => import( /* webpackChunkName: "notetaking" */ '../views/projects/ProjectSessions.vue'),
    meta:{
      isCollaborative: true,
    }
    // meta: {
    //   requiresAuth: true
    // }
  },
  {
    path: '/project/:projectId/script',
    name: 'Project Script',
    component: () => import( /* webpackChunkName: "notetaking" */ '../views/projects/question-scripts/Script.vue'),
    meta:{
      isCollaborative: true,
    }
  },
  {
    path: '/project/:projectId/analysis',
    name: 'Project Analysis',
    component: () => import( /* webpackChunkName: "notetaking" */ '../views/analysis/index.vue'),
    meta:{
      isCollaborative: true,
    }
    // meta: {
    //   requiresAuth: true
    // }
  },
  {
    path: '/project/:projectId/tags',
    name: 'Project Tags',
    component: () => import( /* webpackChunkName: "notetaking" */ '../views/projects/ProjectTags.vue')
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   component: () => import( /* webpackChunkName: "notetaking" */ '../views/About.vue')
  // },
  // {
  //   path:'/test',
  //   name: 'Test',
  //   component: () => import( /* webpackChunkName: "test" */ '../views/Test.vue')
  // }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// navigation guard to check for logged in users
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  // Checks if the current route allows collaborative actions
  if (requiresAuth && !auth.currentUser) {
    next('/login')
  } else {
    next()
  }
})

export default router