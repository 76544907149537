import {
    auth,
    tagsCollection,
    annotationsCollection
  } from "@/firebase";
import CONSTANTS from '../../constants/miscellaneous.js';
import axios from 'axios';

const getAllWorkspaceTags=async(workspaceId)=>{
    const tagsData = await tagsCollection.where("workspace_id","==",workspaceId).get()
    let tags = [];
    for (const tag of await tagsData.docs){
        tags.push({
            ...tag.data(),
            id:tag.id
        })
    }
    return tags;
}

const getAllTags=async(projectId,isCollaborator=false)=>{
        const tagsData= isCollaborator ? await tagsCollection
                                            .where("projectId","==",projectId)
                                            // .orderBy("createdOn","desc")
                                            .get()
                                      :  await tagsCollection
                                            .where("userId","==",auth.currentUser.uid)
                                            .where("projectId","==",projectId)
                                            // .orderBy("createdOn","desc")
                                            .get()
                    
    // console.log(tagsData)
    let tags=[];
    for(const tag of await tagsData.docs){
        // console.log(project.id,'-->',project.data().name)
        // console.log(tag.id,tag.data())
        tags.push({
            ...tag.data(),
            id:tag.id,
        })
        
    }                        

    return tags;
}

const deleteTagsForProject=async(projectId)=>{
    try{
        const tags=await tagsCollection
        .where("userId","==",auth.currentUser.uid)
        .where("projectId","==",projectId)
        // .orderBy("createdOn","desc")
        .get()

        if(tags.docs.length===0){
            // console.log('Empty tagboard');
            return{ status:'success',message:'Tagboard is empty', statusCode:10}
        }
        for(const tag of await tags.docs){
        await tag.ref.delete()        
        } 
        return{ status:'success',message:'Tagboard deleted', statusCode:20}


    }catch(error){
        return {status:'failure',erorr:error};
    }
    
}

const createNewTag=async(newTag)=>{
    const tagObject={
        ...newTag,
        createdOn: new Date(),
        userId: auth.currentUser.uid,
        updatedOn: new Date(),
        lastUpdatedBy:  auth.currentUser.uid

    }
    try{
        const newTag=await tagsCollection.add(tagObject);
        return {
            status:'success',
            newTag:{
                tagId:newTag.id,
                ...tagObject
            }
        }
    }
    catch(error){
        // console.log(error);
        return{
            status:'error',
            error:error
        }
    }
    
    
}

const createNewTagForProject=async(newTag,projectId,workspaceId)=>{
    const tagObject={
        ...newTag,
        createdOn: new Date(),
        userId: auth.currentUser.uid,
        updatedOn: new Date(),
        projectId: projectId,
        workspace_id:workspaceId,
        taggedNoteCount:0,
        lastUpdatedBy:  auth.currentUser.uid

    }
    try{
        const newTag=await tagsCollection.add(tagObject);
        return {
            status:'success',
            newTag:{
                id:newTag.id,
                ...tagObject
            }
        }
    }
    catch(error){
        // console.log(error);
        return{
            status:'error',
            error:error
        }
    }
    
    
}

const deleteTag=async(tagId)=>{

    try{
        const tagToBeDeleted=await tagsCollection.doc(tagId)
        const deletedTagResponse=await tagToBeDeleted.delete();
        return {
            status:'success'
        }
    }catch(error){
        return{
            status:'error',
            error:error
        }
    }
    
}

const createClip=async(annotationId)=>{
    // Trigger clip creation
    const data = JSON.stringify({
                "annotation_id": annotationId,
    });
    const axios_config = {
                method: 'post',
                url: 'https://us-central1-poc-project-309214.cloudfunctions.net/create_clip',
                headers: {
                  'Content-Type': 'application/json'
                },
                data: data
    };
    try{
        // @ts-ignore
        const  newClipResponse=await axios(axios_config)
        console.log({newClipResponse});
        const updatedAnnotation=await annotationsCollection.doc(annotationId).update({
            updatedOn:new Date(),
            type:'clip',
            lastUpdatedBy:  auth.currentUser.uid
        })
        return { status:'success',response:{newClipResponse,updatedAnnotation} }
    }catch(error){
        console.log('Error from clip creation API ',error);
        return{
            status:'error',
            error:error
        }
    }
    
     
                
}

// Calls the manage_project_tags service to delete a tag and remove it from all the notes
const deleteTagFromProject=async(tag,projectId)=>{
    console.log('in deleteTagFromProject')
    try{
        const requestBody={
            "user_id": auth.currentUser.uid,
            "tag_ids": [tag.id],
            "project_id":projectId
        }
        
        const requestUrl="https://us-central1-poc-project-309214.cloudfunctions.net/manage_project_tags/delete-tags"
        const response=await axios.post(requestUrl,requestBody)
        return {status:'success',response};
    }catch(error){
        return { status:'error',error};
    }
}

// Calls the manage_project_tags service to edit a tag and update related notes
const editProjectTag=async(tag,projectId)=>{
    console.log('in editProjectTag')
    try{
        const requestBody={
            "user_id": auth.currentUser.uid,
            "project_id": projectId,
            "updated_tag": {
              "name":tag.name,
              "color":tag.color,
              "tagId":tag.id
            }
          }
        const requestUrl="https://us-central1-poc-project-309214.cloudfunctions.net/manage_project_tags/edit-tag"
        const response=await axios.post(requestUrl,requestBody)
        return {status:'success',response};
    }catch(error){
        return { status:'error',error};
    }
}

export default {
    getAllWorkspaceTags,
    getAllTags,
    createNewTag,
    createNewTagForProject,
    deleteTag,
    deleteTagsForProject,
    createClip,
    deleteTagFromProject,
    editProjectTag
}