<template>
  <v-content fluid>
    <VerifyEmailModal ref="verifyEmailModalRef" v-on:google-signin-click="clickedOnGoogleSignIn" v-on:close-modal="closeEmailVerificationModal"/>
    <!-- <SearchOptIn v-if="showSearchCTA" v-on:dismiss-search-cta="dismissSearchCTA" v-on:join-waitlist="joinWaitlist"/> -->
    <PlanBadge/>
    <UploadFileModal
        :clickedFrom="'banner'"
        :allProjects="allProjects"
        :uploadDialog="showUploadModal"
        :uploadAlertText="uploadAlertText"
        :upfiles="upfiles"
        :mediaFiletoUpload="mediaFiletoUpload"
        :selectedFiles="selectedFiles"
        :isUploadingMediaFiles="isUploadingMediaFiles"
        v-on:clear-selected-files="clearSelectedFiles"
        v-on:handle-media-files-to-upload="handleMediaFilesUpload"
        v-on:remove-selected-file="removeSelectedFile"
        v-on:close-upload-dialog="closeUploadDialog"
        v-on:start-media-files-upload="startMediaFilesUpload"
        :defaultProject="defaultProject"
        v-on:create-new-project="createNewProject"

    />
    <div id="dashboard">
        <!-- LIST -->
        <div class="col2">
            <div>
              <template v-if="isLearnMoreModal">
                <v-col cols="6" style="position: relative">

                  <v-dialog
                    hide-overlay
                    v-model="isLearnMoreModal"
                    max-width="243"
                    content-class="learn-more-dialog-outer"
                    @click:outside="learnMorePage = false"
                  >
                    <v-card
                      class="pa-4 learn-more-dialog-inner"
                      rounded="lg"
                      style="height: 270px"
                    >
                      <div
                        style="
                          display: flex;
                          flex-direction: row;
                          justify-content: space-between;
                        "
                      >
                        <div>
                          <p
                            style="
                              font-weight: 700;
                              font-size: 16px;
                              line-height: 150%;
                              color: #b7791f;
                            "
                            v-show="!learnMorePage"
                          >
                            📣 Announcing Looppanel Notetaker
                          </p>
                          <p
                            style="
                              font-weight: 700;
                              font-size: 16px;
                              line-height: 150%;
                              color: #b7791f;
                            "
                            v-show="learnMorePage"
                          >
                            👆 Paste an ongoing Zoom meeting link in the box
                          </p>
                        </div>
                        <v-btn
                          light
                          icon
                          @click="
                            isLearnMoreModal = !isLearnMoreModal;
                            learnMorePage = !learnMorePage;
                          "
                        >
                          <v-icon> ri-close-line </v-icon>
                        </v-btn>
                      </div>
                     
                      <div>
                        <p
                          style="
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 150%;
                            color: #999999;
                          "
                          v-show="!learnMorePage"
                        >
                          We’re launching Looppanel’s recorder that will record
                          calls taking place on Zoom, allow you to take live
                          notes, and get an instant transcript, all while staying
                          in Looppanel.
                        </p>
                        <p
                          style="
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 150%;
                            color: #999999;
                          "
                          v-show="learnMorePage"
                        >
                          Once you’ve done that, we’ll start recording your
                          ongoing Zoom call and you can take live notes!
                        </p>
                      </div>
                      <v-card-actions class="mt-auto">
                        <v-btn
                          :disabled="!learnMorePage"
                          height="38"
                          class="loopone--text rounded-lg mr-auto pr-4"
                          style="
                            font-weight: 800 !important;
                            border-radius: 12px !important;
                            background-color: #fff3dc;
                          "
                          elevation="0"
                          small
                          light
                          @click="learnMorePage = !learnMorePage"
                        >
                          <template v-slot:default>
                            <v-icon small> ri-arrow-left-s-line </v-icon>
                            <p
                              v-show="learnMorePage"
                              style="font-weight: 700 !important; font-size: 12px"
                              class="mb-0 loopone--text"
                            >
                              Prev
                            </p>
                            <p
                              v-show="!learnMorePage"
                              style="font-weight: 700 !important; font-size: 12px"
                              class="mb-0 grey--text"
                            >
                              Prev
                            </p>
                          </template>
                        </v-btn>
                        <v-btn
                          v-show="!learnMorePage"
                          height="38"
                          class="loopone--text rounded-lg ml-auto pl-4"
                          style="
                            font-weight: 800 !important;
                            border-radius: 12px !important;
                            background-color: #fff3dc;
                          "
                          elevation="0"
                          small
                          light
                          @click="learnMorePage = !learnMorePage"
                        >
                          <p
                            style="font-weight: 700 !important; font-size: 12px"
                            class="mb-0 loopone--text"
                          >
                            Next
                          </p>
                          <v-icon small> ri-arrow-right-s-line </v-icon>
                        </v-btn>
                        <v-btn
                          v-show="learnMorePage"
                          height="38"
                          class="loopone white--text rounded-lg ml-auto pl-4"
                          style="
                            font-weight: 800 !important;
                            border-radius: 12px !important;
                            border: 1px solid #e6e6e6;
                            background-color: white;
                          "
                          elevation="0"
                          small
                          light
                          @click="
                            isLearnMoreModal = !isLearnMoreModal;
                            learnMorePage = !learnMorePage;
                          "
                        >
                          <p
                            style="font-weight: 700 !important; font-size: 12px"
                            class="mb-0 white--text"
                          >
                            Done
                          </p>
                          <v-icon small> ri-check-line </v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                </v-col>
              </template>

              <!-- //TODO: Abstract this into a separate <AddNewRecording/> component  -->
              <!-- Import recordings -->

              <v-col cols="12" class="pl-0 ">
                <!-- <div class="start-recording-container">
                  <StartRecording :noResponsive="true"/>
                </div> -->
                <v-row class="no-gutters dashboard-action-row mb-10">
                  <div class="dashboard-action-row">
                    <!-- <v-menu
                      :nudge-bottom="5"
                      offset-y
                      content-class="elevation-2"
                      rounded="lg"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <button
                          large
                          :class="showUploadPulsatingBorder ? 'add-call-btn pulsating-border' : 'add-call-btn'"
                          v-bind="attrs"
                          v-on="on"
                          elevation="0"
                          @click="trackUploadCallClick"
                        >
                        <i class="ri-upload-line"></i>
                        Upload call
                        <i class="ri-arrow-down-s-line"></i>
                      </button>
                      </template>

                      <v-card>
                        <v-col>
                          <UploadFileModal
                            :allProjects="allProjects"
                            :uploadDialog="showUploadModal || uploadDialog"
                            :uploadAlertText="uploadAlertText"
                            :upfiles="upfiles"
                            :mediaFiletoUpload="mediaFiletoUpload"
                            :selectedFiles="selectedFiles"
                            :isUploadingMediaFiles="isUploadingMediaFiles"
                            v-on:clear-selected-files="clearSelectedFiles"
                            v-on:handle-media-files-to-upload="handleMediaFilesUpload"
                            v-on:remove-selected-file="removeSelectedFile"
                            v-on:close-upload-dialog="closeUploadDialog"
                            v-on:start-media-files-upload="startMediaFilesUpload"
                            :defaultProject="defaultProject"
                            v-on:create-new-project="createNewProject"

                          />

                          <v-spacer></v-spacer>

                          
                          <ZoomImport
                            :zoomIntegration="zoomIntegration"
                            :zoomRefreshInProgress="zoomRefreshInProgress"
                            :zoomRecordingsList="zoomRecordingsList"
                            v-on:refresh-recording-list="refreshRecordingList"
                            v-on:trigger-session-creation-from-recording="triggerSessionCreationFromRecording"
                            :allProjects="allProjects"
                            :defaultProject="defaultProject"
                          />
                          
                        </v-col>
                      </v-card>
                    </v-menu> -->
                    <UpgradePlanTooltip
                      :showTooltip="trialEnded && !subscriptionActive" 
                      :message="'Your plan has ended. Please upgrade to create new project'"
                      renderedIn="Create new project on Dashboard"
                    >
                      <button 
                        @click="handleToggleCreateNewProjectModal" 
                        :disabled="trialEnded && !subscriptionActive" 
                        :class="showUploadPulsatingBorder ? 'create-new-project-btn pulsating-border' : 'create-new-project-btn'"
                        >
                        <i class="ri-add-line"></i>
                        Create new project
                      </button>
                    </UpgradePlanTooltip>

                    <CreateNewProjectModal
                      :showCreateNewProjectModal="showCreateNewProjectModal"
                      v-on:toggle-modal="handleToggleCreateNewProjectModal"
                      v-on:create-project="createProject"
                    />
                    </div>
                    <div class="dashboard-action-row">

                      <button :disabled="calendarSyncInProcess" v-if="canSyncCalendar" @click="syncCalendar" class="sync-calendar-btn">
                        <template v-if="calendarSyncInProcess">
                          <v-progress-circular
                                    :size="15"
                                    :width="2"
                                    color="#666666"
                                    indeterminate
                        ></v-progress-circular>
                            Syncing your calendar...
                        </template>
                        <template v-else>
                            <i class="ri-refresh-line"></i>
                            Sync Calendar
                        </template>
                      </button>
                      
                    <template v-if="showCallStatusCard && latestMeeting">
                    <div  class="pl-4 pt-3">

                      <!-- For upcoming call -->
                      <!-- <CallStatus
                        v-if="callStatus.status==='upcoming'"
                        :callStatus="callStatus.status"
                        :callStatusText="callStatus.statusText"
                        :title="latestMeeting.title"
                        :subtitle="getFormattedMeetingTime()"
                        :showIconInCTA="true"
                        :ctaEvent="'share-notetaking-link'"
                        :ctaText="'share note taking link'"
                        v-on:share-notetaking-link="copyNotesUrl"
                      /> -->
                      <SessionStatus v-if="callStatus.status==='upcoming'" type="upcoming-call" :title="latestMeeting.title">
                            <template v-slot:status>
                              <div class="upcoming-call-icon mr-5">
                                <i class="ri-time-line"></i>
                                {{getFormattedMeetingTime()}}
                              </div>
                            </template>
                            <template v-slot:cta>
                              <button @click="copyNotesUrl" class="upcoming-call-btn ml-10">
                                <i class="ri-file-copy-line"></i>
                                Copy Notetaking Link
                              </button>
                            </template>
                      </SessionStatus>

                      <!-- For error  -->
                      <!-- <CallStatus
                        v-if="callStatus.status==='error'"
                        :callStatus="callStatus.status"
                        :callStatusText="callStatus.statusText"
                        :title="latestMeeting.title"
                        :subtitle="getFormattedMeetingTime()"
                        :ctaEvent="'go-to-notetaking-link'"
                        :ctaText="'go to note taking link'"
                        v-on:go-to-notetaking-link="goToNotetakingLink"
                        :ctaBtnColor="'#FFFFFF'"
                        :ctaTextColor="'#5B7EDB'"
                        :showIconInCTA="true"
                      /> -->
                      
                      <!-- <CallStatus
                        v-if="callStatus.status==='waiting'"
                        :callStatus="callStatus.status"
                        :callStatusText="callStatus.statusText"
                        :title="latestMeeting.title"
                        :subtitle="getFormattedMeetingTime()"
                        :ctaEvent="'go-to-notetaking-link'"
                        :ctaText="'Return to note taking'"
                        v-on:go-to-notetaking-link="goToNotetakingLink"
                        :showIconInCTA="true"
                        :customCtaTemplate="true"
                      >
                        <template v-slot:custom-cta>
                          <div>
                          
                            <button class="return-to-notetaking-btn"  @click="goToNotetakingLink">
                              Return to note taking
                              <i class="ri-arrow-right-line"></i>
                            </button>
                        
                          </div>

                        </template>
                      </CallStatus> -->
                      <SessionStatus 
                          v-if="callStatus.status==='waiting'" textColor="#B7791F" 
                          :type="callStatus.status" :title="callStatus.statusText">
                            <template v-slot:status>
                              <div class="waiting-call-icon">
                                <i class="ri-information-line"></i>
                              </div>
                            </template>
                            <template v-slot:cta>
                              <button  @click="copyNotesUrl" class="ongoing-call-btn ml-10">
                                  <i class="ri-file-copy-line"></i>
                                  Copy Notetaking Link
                              </button>
                            </template>
                      </SessionStatus>
                      
                      <!-- <CallStatus
                        v-if="callStatus.status==='ongoing'"
                        :callStatus="callStatus.status"
                        :callStatusText="callStatus.statusText"
                        :title="latestMeeting.title"
                        :subtitle="getFormattedMeetingTime()"
                        :ctaEvent="'go-to-notetaking-link'"
                        :ctaText="'Return to note taking'"
                        v-on:go-to-notetaking-link="goToNotetakingLink"
                        :showIconInCTA="true"
                        :customCtaTemplate="true"
                      >
                        <template v-slot:custom-cta>
                          <div>
                            <button class="share-notetaking-link-btn mb-2" @click="copyNotesUrl">
                            <i class="ri-share-line"></i>
                              Share note taking link
                              
                            </button>
                          
                            <button class="return-to-notetaking-btn"  @click="goToNotetakingLink">
                              Return to note taking
                              <i class="ri-arrow-right-line"></i>
                            </button>
                        
                          </div>

                        </template>
                      </CallStatus> -->

                      <SessionStatus v-if="callStatus.status==='ongoing'" :type="callStatus.status" :title="latestMeeting.title">
                            <template v-slot:status>
                              <div class="ongoing-call-icon mr-5">
                                <i class="ri-checkbox-blank-circle-fill"></i>
                                Recording
                              </div>
                            </template>
                            <template v-slot:cta>
                              <button  @click="goToNotetakingLink" class="ongoing-call-btn ml-10">
                                <i class="ri-arrow-right-line"></i>
                                Open Notetaker
                              </button>
                            </template>
                      </SessionStatus>


                      </div>
                    </template>
                    <template v-else>
                      <template v-if="showNewFeatureCard">
                      <!-- <NewFeature
                          feature="calendar"
                          title="Looppanel can now auto record your scheduled calls"
                          subtitle="Connect your Google Calendar to get started"
                          ctaText="set up google calendar"
                          ctaEvent="setup-google-calendar"
                          v-on:setup-google-calendar="setupGoogleCalendar"
                      /> -->
                          <SessionStatus type="connect-calendar" title="Connect your calendar to auto-record Zoom calls">
                            <template v-slot:status>
                              <div class="connect-calendar-icon"><i class="ri-calendar-event-fill"></i></div>
                            </template>
                            <template v-slot:cta>
                              <button @click="toggleConnectCalendarModal" class="connect-calendar-btn">
                                Connect Calendar
                              </button>
                            </template>
                          </SessionStatus>
                    </template>
                    </template>
                    <!-- disable invite members: {{  disableInviteMembers }} -->
                    <UpgradePlanTooltip 
                      type="info"
                      :showTooltip="disableInviteMembers" 
                      :message="'To invite members to your workspace and collaborate, upgrade to the Teams Plan'"
                      renderedIn="Invite Members Button on Dashboard"
                    >
                      <InviteWorkspaceMembersModal :disabled="disableInviteMembers"/>
                    </UpgradePlanTooltip>
                  </div>
                <!-- <SessionStatus/> component ends -->


                </v-row>

              </v-col>

              <!-- Component complete -->
            </div>

            <!-- <v-row>
              <v-col cols="12 pl-0">
                <session-search v-if="sessions" :data="sessions" searchData="sessions"/>
              </v-col>
            </v-row> -->
          <!-- <SessionSearch :data="sessions" searchData="sessions"/> -->
          <RecentProjects ref="recentProjectsRef"/>
          <RecentProjects type="team"  ref="recentTeamRef" class="mt-10"/>
          <!-- <RecentProjects type="shared" ref="sharedProjectsRef" class="mt-10"/> -->
          
          <RecentCalls 
            :prevBtnDisabled="prevBtnDisabled"
            :nextBtnDisabled="nextBtnDisabled"
            :sessions="sessions"
            :disabledSessions="disabledSessions"
            :loadingStatus="loadingStatus"
            :calls="cardsList"
            :allProjects="recentCallsProjects"
            :projectDropdownOpened="projectDropdownOpened"
            v-on:refresh-page="refreshSession"
            v-on:disable-session="disableSession"
            v-on:enable-session="enableSession"
            v-on:show-project-dropdown="showProjectDropdown"
            v-on:hide-project-dropdown="hideProjectDropdown"
            v-on:delete-call="deleteTranscript"
            v-on:next="next"
            v-on:prev="prev"
          />

          <!-- v-if="transcripts.length" -->
          <div v-if="true">
            <!-- <v-container class="pl-0 pr-0"> -->
              
              <!-- <v-row no-gutters>
                <v-col cols="6">
                  <v-row no-gutters class="ma-0 pa-0">
                    <v-icon
                      color="#666666"
                      class="pl-2"
                      style="
                        border-top: thin solid #e6e6e6 !important;
                        border-bottom: thin solid #e6e6e6 !important;
                        border-left: thin solid #e6e6e6 !important;
                        border-top-left-radius: 12px;
                        border-bottom-left-radius: 12px;
                      "
                      small
                      >ri-search-line</v-icon
                    >

                    <input
                      class="pl-2"
                      style="
                        outline: none;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 24px;
                        display: flex;
                        align-items: center;
                        height: 40px;
                        width: 80%;
                        border-top: thin solid #e6e6e6 !important;
                        border-bottom: thin solid #e6e6e6 !important;
                        border-right: thin solid #e6e6e6 !important;
                        border-top-right-radius: 12px;
                        border-bottom-right-radius: 12px;
                        color: #4c4c4c;
                      "
                      type="text"
                      v-model="search"
                      placeholder="Search for a call by name"
                    />
                  </v-row>
                </v-col>
                <v-col cols="6" class="text-right pr-2" align-self="center">
                  <p
                    class="ma-0"
                    style="
                      font-weight: 700;
                      font-size: 14px;
                      line-height: 150%;
                      /* identical to box height, or 21px */

                      display: flex;
                      align-items: center;
                      letter-spacing: -0.03em;

                      /* Neutrals/500 */

                      color: #666666;
                    "
                  >
                    {{ filteredCards.length }} Recordings
                  </p>
                </v-col>
              </v-row> -->
              <v-row v-if="search.length != 0">

                <!-- //TODO: Abstract the rendering and displaying of session cards into separate <Sessions/> component -->
            
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                  v-for="(transcript, index) in filteredCards"
                  :key="index"
                >
                  <!-- //TODO: Abstract this into separate <SessionCard/> component -->
                  <v-card
                    elevation="2"
                    style="
                      border-radius: 12px;
                      box-shadow: 1px 1px 10px 0px !important;
                      color: #66666642;
                      min-height: 15.5em;
                      position: relative;
                    "
                  >
                    <router-link
                      @click.native="trackOpenTranscriptEvent(transcript.id)"
                      :to="{
                        path: '/editor',
                        query: {
                          fileName: transcript.fileName,
                          transcriptId: transcript.id,
                        },
                      }"
                    >
                      <v-img
                        v-if="transcript.posterUrl"
                        style="
                          border-top-left-radius: 12px;
                          border-top-right-radius: 12px;
                        "
                        height="160"
                        aspect-ratio="1"
                        :src="transcript.posterUrl"
                      ></v-img>
                      <v-img
                        v-else
                        style="
                          border-top-left-radius: 12px;
                          border-top-right-radius: 12px;
                        "
                        height="160"
                        src="loop_placeholder_grey.png"
                      ></v-img>

                      <v-row  class="mx-0 pt-2">
                        <v-card-title class="pb-1">
                          <!-- //!Remove inline styling   -->
                          <h3
                            style="
                              color: #666666;
                              font-weight: 600;
                              letter-spacing: -0.5px;
                              line-height: 160%;
                              font-size: 18px;
                            "
                          >
                            {{ transcript.fileName | trimLength }}
                          </h3>
                        </v-card-title>

                        <v-spacer></v-spacer>

                        <!-- <v-tooltip
                          bottom
                          v-if="transcript.shareable"
                          color="loopfive"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                              <v-icon left class="mt-5 mr-2" color="loopone"
                                >ri-group-line</v-icon
                              >
                            </span>
                          </template>
                          <span class="loopone--text">Shared</span>
                        </v-tooltip> -->

                      </v-row>
                    </router-link>
                    <v-card-text class="pt-0 pr-0">
                      <v-row class="mx-0">
                        <v-icon size="16" class="mr-0.5">ri-time-line</v-icon>

                        <!-- //!Remove inline styling   -->
                        <span
                          style="
                            color: #666666;
                            font-weight: 400;
                            font-size: 14px;
                            letter-spacing: 0px;
                          "
                          v-if="transcript.processed"
                          >{{
                            transcript.createdOn | formatMessageProcessed
                          }}</span
                        >
                        <span
                          v-if="!transcript.processed"
                          class="status in-progress"
                        >
                          {{
                            transcript.createdOn | formatMessageUnprocessed
                          }}</span
                        >
                        <v-spacer></v-spacer>
                        <!-- Delete button -->
                        <div
                          v-if="
                            !isDeleteDisabled(
                              transcript.createdOn,
                              transcript.processed
                            )
                          "
                        >
                          <button
                            @click="deleteTranscript(transcript.id, index)"
                            class="
                              bg-white
                              hover:bg-gray-100
                              text-gray-800
                              mt-2
                              mr-1
                              py-1
                              px-2
                              border border-gray-400
                              rounded
                            "
                            :disabled="
                              isDeleteDisabled(
                                transcript.createdOn,
                                transcript.processed
                              )
                            "
                          >
                            <v-icon size="16">ri-delete-bin-line</v-icon>
                          </button>
                        </div>
                      </v-row>
                    </v-card-text>
                  </v-card>
                  <!-- <SessionCard/> Component ends -->
                </v-col>
                <!-- <Sessions/> Component ends -->
              </v-row>

              <v-row v-if="search.length == 0">
                <!-- //TODO: Abstract this into a separate <SessionStatus/> component -->

                
                <!-- <v-card
                  v-if="checkRecordingState()"
                  elevation="2"
                  style="
                    border-radius: 12px;
                    box-shadow: 1px 1px 10px 0px !important;
                    color: #66666642;
                    max-width: 31.3%;
                    min-width: 31.3%;
                    min-height: 15.5em;
                  "
                  class="ma-3"
                >
                  <v-img
                    class="mt-4 mr-4 ml-4"
                    style="
                      width: 92%;
                      height: 50%;
                      position: relative;
                      background: rgba(219, 91, 91, 0.2);
                      border-radius: 16px;
                    "
                  >
                    <p
                      style="
                        position: absolute;
                        top: 45%;
                        left: 8%;
                        font-style: normal;
                        font-weight: 800;
                        font-size: 14px;
                        line-height: 17px;
                        text-transform: uppercase;
                        color: #c53030;
                      "
                    >
                      &#11044; call recording in progress
                    </p>
                  </v-img>
                  <v-row align="center" class="mx-4 mt-3">
                    <v-btn
                      height="38"
                      class="loopone--text rounded-lg"
                      style="
                        width: 100%;
                        font-weight: 800 !important;
                        border-radius: 12px !important;
                        border: 1px solid #e6e6e6;
                        background-color: white;
                      "
                      elevation="0"
                      light
                      @click="copyNotesUrl()"
                    >
                      <p
                        style="font-weight: 800 !important"
                        class="mb-0 loopone--text"
                      >
                        Share note taking link
                      </p>

                      <v-icon right>ri-link</v-icon>
                    </v-btn>
                    <v-btn
                      @click.native="trackOpenNotesEvent()"
                      height="38"
                      class="loopone white--text rounded-lg mt-2"
                      style="
                        width: 100%;
                        font-weight: 800 !important;
                        border-radius: 12px !important;
                        border: 1px solid #e6e6e6;
                        background-color: white;
                      "
                      elevation="0"
                      light
                      :to="`/notes/${userProfile.notetaking_room_id}`"
                    >
                      <p
                        style="font-weight: 800 !important"
                        class="mb-0 white--text"
                      >
                        Return to note taking
                      </p>

                      <v-icon right>ri-arrow-right-s-line</v-icon>
                    </v-btn>
                  </v-row>
                </v-card>
                <v-card
                  v-else
                  elevation="2"
                  style="
                    border-radius: 12px;
                    box-shadow: 1px 1px 10px 0px !important;
                    color: #66666642;
                    max-width: 31.3%;
                    min-width: 31.3%;
                    min-height: 15.5em;
                  "
                  class="ma-3"
                >
                  <v-img
                    class="mt-4 mr-4 ml-4"
                    style="
                      width: 92%;
                      height: 50%;
                      position: relative;
                      background: white;
                      border-radius: 16px;
                    "
                  >
                    <p
                      style="
                        position: absolute;
                        top: 50%;
                        left: 10%;
                        font-style: normal;
                        font-weight: 800;
                        font-size: 14px;
                        line-height: 17px;
                        text-transform: uppercase;
                        color: #5b7edb;
                      "
                    >
                      &#11044; Looppanel is not recording a call
                    </p>
                  </v-img>
                  <v-row align="center" class="mx-4 mt-2">
                    <v-btn
                      height="38"
                      class="loopone--text loopsix rounded-lg"
                      style="
                        width: 100%;
                        font-weight: 800 !important;
                        border-radius: 12px !important;
                        border: 1px solid #e6e6e6;
                        background-color: white;
                      "
                      elevation="0"
                      light
                      @click="copyNotesUrl()"
                    >
                      <p
                        style="font-weight: 800 !important"
                        class="mb-0 loopone--text"
                      >
                        Share note taking link
                      </p>

                      <v-icon right>ri-link</v-icon>
                    </v-btn>
                    <v-btn
                      height="38"
                      class="loopone--text rounded-lg mt-2"
                      style="
                        width: 100%;
                        font-weight: 800 !important;
                        border-radius: 12px !important;
                        border: 1px solid #e6e6e6;
                        background-color: white;
                      "
                      elevation="0"
                      light
                      @click="
                        isLearnMoreModal = !isLearnMoreModal;
                        learnMorePage = false;
                      "
                    >
                      <p
                        style="font-weight: 800 !important"
                        class="mb-0 loopone--text"
                      >
                        Learn More
                      </p>

                      <v-icon right>ri-arrow-right-s-line</v-icon>
                    </v-btn>
                  </v-row>
                </v-card> -->


                <!-- <v-col
                  v-show="!loadingStatus && !transcript.recordingStatus"
                  cols="12"
                  sm="4"
                  md="4"
                  v-for="(transcript, index) in cardsList"
                  :key="transcript.id"
                >
                 
                  <v-card
                    elevation="0"
                    :class="
                      disabledSessions.includes(transcript.id) ? 'moving' : ''
                    "
                    style="
                      border-radius: 12px;
                      border: 1px solid #EDEDED;
                      color: #66666642;
                      height:269px;
                      min-height: 15.5em;
                      position: relative;
                    "
                  >
                    <project-assignment
                      :allProjects="allProjects"
                      :key="transcript.id"
                      :projectId="transcript.projectId"
                      :readOnly="transcript.scriptId"
                      :sessionId="transcript.id"
                      v-on:refresh-page="refreshSession"
                      v-on:disable-session="disableSession"
                      v-on:enable-session="enableSession"
                      v-on:show-project-dropdown="showProjectDropdown"
                      v-on:hide-project-dropdown="hideProjectDropdown"
                      :projectDropdownOpened="projectDropdownOpened"
                    />

                    <router-link
                      v-if="!transcript.recordingStatus"
                      @click.native="trackOpenTranscriptEvent(transcript.id)"
                      :is="
                        disabledSessions.includes(transcript.id)
                          ? 'span'
                          : 'router-link'
                      "
                      :to="{
                        path: '/editor',
                        query: {
                          transcriptId: transcript.id,
                          projectId: transcript.projectId,
                        },
                      }"
                    >
                      <v-img
                        v-if="transcript.posterUrl"
                        :class="
                          disabledSessions.includes(transcript.id)
                            ? 'dim'
                            : 'undim'
                        "
                        style="
                          border-top-left-radius: 12px;
                          border-top-right-radius: 12px;
                        "
                        height="184"
                        aspect-ratio="1"
                        :src="transcript.posterUrl"
                      ></v-img>
                      <v-img
                        v-else
                        style="
                          border-top-left-radius: 12px;
                          border-top-right-radius: 12px;
                        "
                        height="160"
                        src="loop_placeholder_grey.png"
                      ></v-img>
                      <v-row class="mx-0 pt-2">
                        <v-card-title class="pb-1">
                          <h3
                            style="
                              color: #666666;
                              font-weight: 600;
                              letter-spacing: -0.5px;
                              line-height: 160%;
                              font-size: 18px;
                            "
                          >
                            <template
                              v-if="disabledSessions.includes(transcript.id)"
                              >Session move in progress...</template
                            >
                            <template v-else>{{
                              transcript.fileName | trimLength
                            }}</template>
                          </h3>
                        </v-card-title>

                        <v-spacer></v-spacer>
                      </v-row>
                    </router-link>
                    <router-link
                      v-else
                      :to="`/notes/${userProfile.notetaking_room_id}`"
                    >
                      <v-img
                        v-if="transcript.posterUrl"
                        style="
                          border-top-left-radius: 12px;
                          border-top-right-radius: 12px;
                        "
                        height="160"
                        aspect-ratio="1"
                        :src="transcript.posterUrl"
                      ></v-img>
                      <v-img
                        v-else
                        style="
                          border-top-left-radius: 12px;
                          border-top-right-radius: 12px;
                        "
                        height="160"
                        src="loop_placeholder_grey.png"
                      ></v-img>
                      <v-row class="mx-0 pt-2">
                        <v-card-title class="pb-1">
                          <h3
                            style="
                              color: #666666;
                              font-weight: 600;
                              letter-spacing: -0.5px;
                              line-height: 160%;
                              font-size: 18px;
                            "
                          >
                            {{ transcript.fileName | trimLength }}
                          </h3>
                        </v-card-title>
                        <v-spacer></v-spacer>
                      </v-row>
                    </router-link>
                    <v-card-text class="pt-0 pr-0">
                      <v-row
                        v-if="!transcript.recordingStatus"
                        align="center"
                        class="mx-0"
                      >
                        <v-icon
                          v-if="transcript.processed"
                          size="15"
                          class="mr-0.5"
                          >ri-time-line</v-icon
                        >
                        <span
                          style="
                            color: #666666;
                            font-weight: 400;
                            font-size: 14px;
                            letter-spacing: 0px;
                          "
                          v-if="transcript.processed"
                          >{{
                            transcript.createdOn | formatMessageProcessed
                          }}</span
                        >
                        <span
                          v-if="!transcript.processed"
                          class="status in-progress"
                        >
                          {{
                            transcript.createdOn | formatMessageUnprocessed
                          }}</span
                        >
                        <v-spacer></v-spacer>
                        <div
                          v-if="
                            !isDeleteDisabled(
                              transcript.createdOn,
                              transcript.processed
                            )
                          "
                        >
                          <button
                            @click="deleteTranscript(transcript.id, index)"
                            class="
                              bg-white
                              hover:bg-gray-100
                              text-gray-800
                              mt-2
                              mr-1
                              py-1
                              px-2
                              border border-gray-400
                              rounded
                            "
                            :style="{
                              visibility: disabledSessions.includes(
                                transcript.id
                              )
                                ? 'hidden'
                                : 'visible',
                            }"
                            :disabled="
                              isDeleteDisabled(
                                transcript.createdOn,
                                transcript.processed
                              )
                            "
                          >
                            <v-icon size="16">ri-delete-bin-line</v-icon>
                          </button>
                        </div>
                      </v-row>
                      <v-row v-else class="mx-0 mt-2">
                        <span
                          style="
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            color: #c53030;
                          "
                        >
                          &#11044;
                        </span>
                        <span
                          style="
                            padding-left: 4px;
                            color: #666666;
                            font-weight: 400;
                            font-size: 14px;
                            letter-spacing: 0px;
                          "
                        >
                          {{
                            transcript.createdOn | formatMessageRecordingStatus
                          }}</span
                        >
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col> -->

                <!-- //TODO: Abstract this into a separate <SessionsLoading/> component -->
                <!-- <v-col
                  cols="12"
                  sm="3"
                  md="4"
                  v-for="i in 9"
                  v-show="loadingStatus"
                  :key="i"
                >
                  <v-progress-circular
                      v-if="loadingStatus"
                      v-show="loadingStatus"
                      class="ml-auto mr-auto mt-64"
                      :indeterminate="loadingStatus"
                      color="loopone"
                      size="45"
                      width="5"
                    ></v-progress-circular>

                    <v-skeleton-loader
                    :key="i"
                      class="mx-auto"
                      max-width="370"
                      max-height="240"
                      type="card"
                      elevation="2"
                      style="
                        border-radius: 12px;
                        box-shadow: 1px 1px 10px 0px !important;
                        color: #66666642;
                        min-height: 15.5em;
                      "
                    ></v-skeleton-loader>
    
                  <v-card
                    elevation="2"
                    style="
                      border-radius: 12px;
                      box-shadow: 1px 1px 10px 0px !important;
                      color: #66666642;
                      min-height: 15.5em;
                    "
                  >
                    <v-skeleton-loader
                      :key="i"
                      class="ma-0 pa-0"
                      max-height="160"
                      type="image"
                      elevation="0"
                      style="
                        border-top-left-radius: 12px;
                        border-top-right-radius: 12px;
                        color: #66666642;
                      "
                    ></v-skeleton-loader>

                    <v-row class="mx-0 pt-4">
                      <v-card-title class="pb-1">
                        <v-skeleton-loader
                          :key="i"
                          class="mx-auto"
                          max-width="370"
                          width="200"
                          height="20"
                          type="text"
                          elevation="0"
                          style="color: #66666642"
                        ></v-skeleton-loader>
                      </v-card-title>
                      <v-card-text>
                        <v-skeleton-loader
                          :key="i"
                          max-width="370"
                          width="150"
                          height="20"
                          type="text"
                          elevation="0"
                          style="color: #66666642"
                        ></v-skeleton-loader>
                      </v-card-text>
                    </v-row>
                  </v-card>
                </v-col> -->

                <!-- <SessionsLoading/> Component ends -->
              </v-row>

              <v-row> </v-row>
            <!-- </v-container> -->


          </div>
          <div v-else>
            <v-row no-gutters >
              <v-progress-circular
                v-if="loadingStatus"
                v-show="loadingStatus"
                class="ml-auto mr-auto mt-64"
                :indeterminate="loadingStatus"
                color="loopone"
                size="45"
                width="5"
              ></v-progress-circular>
              <p v-else class="no-results">There are currently no uploads</p>
            </v-row>
          </div>
        </div>

      <LoopActionModal
        :showModal="showOngoingZoomCallModal && clickedStartRecordingFirstTime"
        :showCtaHelper="true"
        title="Is the link for an Ongoing Zoom call?"
        ctaEvent="start-recording"
        v-on:start-recording="startRecording"
        v-on:toggle-modal="showOngoingZoomCallModal=!showOngoingZoomCallModal"
        ctaBtnColor="#5b7edb"
        ctaText="Start recording"
      >
        <template v-slot:content>
          <p class="ongoing-call-text">
            Lopppanel Notetaker <span>can only record ongoing zoom calls.</span> 
            Make sure the <span>link you pasted is of an ongoing call.</span>
          </p>
        </template>
        <template v-slot:ctaHelper-template>
           <v-checkbox
              v-model="dontShowPopupAgain"
              dense
              class="m-0 p-0"
              style="font-size:12px"
              color="#5b7edb"
            >
              <template v-slot:label>
                <p class="mb-0 p-0 dont-show-label">
                  Don’t show this pop up again
                </p>
              </template>
            </v-checkbox>
        </template>
      </LoopActionModal>

       <LoopActionModal
        :showModal="showNotetakingDemoModal"
        :showHighlightedInfo="true"
        :isLongTitle="true"
        :hideCloseButton="true"
        v-on:toggle-modal="showNotetakingDemoModal=!showNotetakingDemoModal"
        title="🎥  Paste a Zoom Link to Record an Ongoing Call"
        ctaEvent="close-modal"
        ctaBtnColor="#5b7edb"
        ctaText="Got it"
      >
        <template v-slot:highlightedInfo-gif>
          <figure>
            <img 
              style="border-top-left-radius:16px; border-top-right-radius:16px;"
              src="https://firebasestorage.googleapis.com/v0/b/poc-project-309214.appspot.com/o/static%2Frecording-workflow.gif?alt=media&token=104639cc-e6c4-4a40-9bbc-9d76fd75e936"/>
          </figure>
        </template>
        <template v-slot:content>
          <p class="empty-call-text">
            Looppanel now records your Zoom calls for you!
          </p>
        </template>
      </LoopActionModal>

      <ConnectCalendarModal
        :connectCalendarDialog="connectCalendarDialog"
        v-on:close-connect-calendar-dialog="toggleConnectCalendarModal"
      />


      <!-- //TODO: Abstract this into separate <DashboardNotifications/> component -->
      <v-snackbar
        v-model="uploadPanel.uploadSnackbar"
        :timeout="uploadPanel.timeout"
        light
        right
      >
        <v-list flat>
          <v-list-group
            :value="true"
            v-for="item in uploadPanel.items"
            :key="item.title"
            no-action
            light
            color="#5b7edb"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title
                  class="font-weight-bold text-indigo text-accent-2"
                  v-text="item.title"
                ></v-list-item-title>
              </v-list-item-content>
            </template>
            <v-card class="overflow-y-auto" max-height="300" elevation="0">
              <v-list-item
                two-line
                v-for="uploadItem in progresslist"
                :key="uploadItem.myFile.name"
              >
                <v-list-item-content>
                  <v-list-item-title
                    class="font-weight-bold"
                    v-text="uploadItem.myFile.name"
                  ></v-list-item-title>
                  <v-list-item-subtitle>
                    <v-progress-linear
                      v-model="uploadItem.progress"
                      color="#D2DCFB"
                      height="25"
                      style="pointer-events: none"
                    >
                      <strong>
                        <template v-if="(Math.ceil(uploadItem.progress))>0">
                          {{ Math.ceil(uploadItem.progress) }}%
                        </template>
                        <template v-else>
                          0%
                        </template>
                      </strong>
                    </v-progress-linear>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-list-group>
        </v-list>
      </v-snackbar>
      <Snackbar
        message="Project created successfully!"
        :showSnackbar="showProjectCreatedSnackbar"
      />
      <Snackbar
        message="Your email has been verified. ✅"
        :showSnackbar="showEmailVerifiedSnackbar"
      />
      <Snackbar
        message="Your Calendar was synced successfully!"
        :showSnackbar="showCalendarSyncedSnackbar"
      />
      <!-- <v-snackbar
        v-model="uploadWarn.snackbar"
        color="#5b7edb"
        shaped
        centered
        timeout="5000"
      >
        <p class="white--text" style="text-align: center; font-weight: bold">
          Hang on a second!
        </p>

        <p class="white--text">
          Please don’t leave or refresh this page until your files have uploaded
        </p>
        <template v-slot:action="{ attrs }">
          <v-btn
            color="5b7edb"
            icon
            v-bind="attrs"
            @click="uploadWarn.snackbar = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar> -->

      <v-snackbar
        color="#5b7edb"
        shaped
        v-model="notetakingCopySnackbar"
        :timeout="1500"
      >
        Note taking link copied!

        <template v-slot:action="{ attrs }">
          <v-btn
            color="white"
            text
            dark
            v-bind="attrs"
            @click="notetakingCopySnackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>

     <v-snackbar
        rounded
        color="#C53030"
        v-model="showBotRecordingError"
        :timeout="3000"
      >
       <i class="ri-error-warning-line"></i>
       Something went wrong. Please try again

        <template v-slot:action="{ attrs }">
          <v-btn
            color="white"
            text
            dark
            v-bind="attrs"
            @click="notetakingCopySnackbar = false"
          >
            <i class="ri-close-line"></i>
          </v-btn>
        </template>
      </v-snackbar>

      <!-- <DashboardNotifications/> Component ends -->

      <!-- Modals -->
      <SelectNotetakingGuide
        :showChoseQuestionScriptModal="showChoseQuestionScriptModal"
        :questionScripts="questionScripts"
        v-on:toggle-question-script-modal="toggleQuestionScriptModal"
        v-on:select-script="selectScript"
      />
      <!-- <template v-if="showChoseQuestionScriptModal ">
         <LoopActionModal
              :showModal="showChoseQuestionScriptModal"
              title="Select a discussion guide"
              ctaEvent="select-script"
              ctaBtnColor="#5B7EDB"
              ctaText="start taking notes"
              v-on:toggle-modal="toggleQuestionScriptModal"
              v-on:select-script="selectScript"
          >
            <template v-slot:content>
              <div>
                <v-radio-group class="m-0" v-model="selectedScript">
                  <v-radio
                    v-for="(script,index) in questionScripts"
                    :key="script.id"
                    :value="script.name"
                    color="#5b7edb"
                    :class="index<questionScripts.length-1 ? 'mb-5' : 'mb-0'"
                  >
                      <template v-slot:label>
                        <QuestionsPreviewMenu :script="script">
                            <template v-slot:custom-activator>
                              <span class="question-script-name">{{script}}</span>
                            </template>
                        </QuestionsPreviewMenu>
                      </template>
                  </v-radio>
                </v-radio-group>
              </div>
            </template>
          </LoopActionModal>
      </template> -->


    </div>
  </v-content>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
// import moment from "moment";
import differenceInMinutes from "date-fns/differenceInMinutes";
import differenceInHours from 'date-fns/differenceInHours'
import formatDistance from "date-fns/formatDistance";
import format from "date-fns/format";
// import CommentModal from "@/components/CommentModal";
import {
  auth,
  storage,
  transcriptsCollection,
  zoomRecordingsCollection,
  projectsCollection,
} from "./../firebase";
import { AtomSpinner } from "epic-spinners";
import projectsAPI from "../api/projects/projects";
import UsersAPI from "../api/user/user";
import LoopActionModal from "@/components/modals/LoopActionModal.vue";
import QuestionScriptsAPI from '../api/question-script/question-scripts';
import sessionsAPI from "../api/sessions/sessions";
import firebase from 'firebase/app';
import NewFeature from "@/components/cards/NewFeature.vue";
import CallStatus from "@/components/cards/CallStatus.vue";
import CalendarAPI from "../api/calendar/calendar";
import CalendarUtils from '../utils/calendar';
import * as Sentry from "@sentry/vue";
import UploadFileModal from "../components/modals/UploadFileModal.vue";
import ZoomImport from '../components/modals/ZoomImport.vue';
import CreateNewProjectModal from "@/components/modals/CreateNewProjectModal.vue";
import RecentProjects from "./dashboard/RecentProjects.vue";
import RecentCalls from "./dashboard/sessions/RecentCalls.vue";
import SessionStatus from "./dashboard/sessions/SessionStatus.vue";
import Snackbar from "@/components/notifications/Snackbar.vue";
import SessionSearch from "./dashboard/sessions/SessionSearch.vue";
import QuestionsPreviewMenu from "@/components/menus/QuestionsPreviewMenu.vue";
import StartRecording from "@/components/misc/StartRecording.vue";
import Miscellaneous from '@/constants/miscellaneous';
import ConnectCalendarModal from "../components/modals/ConnectCalendarModal.vue"
import VerifyEmailModal from '@/components/modals/VerifyEmailModal.vue';
import InviteWorkspaceMembersModal from '@/components/modals/InviteWorkspaceMembersModal.vue';
import PlanBadge from '@/components/cards/PlanBadge.vue';
import UpgradePlanTooltip from '@/components/UpgradePlanTooltip.vue';
import TransactionalEmailAPI from '@/api/TransactionalEmailAPI';
import SelectNotetakingGuide from '@/components/modals/SelectNotetakingGuide.vue';
// import { snapshot } from "yjs";

export default {
  name: "Dashboard",
  components: {
    // AtomSpinner,
    // ProjectAssignment,
    // SessionSearch,
    LoopActionModal,
    // NewFeature,
    // CallStatus,
    UploadFileModal,
    CreateNewProjectModal,
    RecentProjects,
    RecentCalls,
    SessionStatus,
    Snackbar,
    // SessionSearch
    // QuestionsPreviewMenu,
    ConnectCalendarModal,
    VerifyEmailModal,
    InviteWorkspaceMembersModal,
    PlanBadge,
    UpgradePlanTooltip,
    SelectNotetakingGuide,
},
  data() {
    return {
      callStatus:{},
      showCallStatusCard:false,
      showNewFeatureCard:false,
      showBotRecordingError:false,
      allProjects:null,
      recentCallsProjects:null,
      defaultProject: null,
      disabledSessions: [],
      search: "",
      post: {
        content: "",
      },
      filetoUpload: {
        myFile: null,
        processing: false,
        fileURL: null,
      },
      mediaFiletoUpload: {
        myFile: null,
        processing: false,
        fileURL: null,
        progress: null,
      },
      progresslist: [],
      selectedFiles: [],
      pageNumber: 0,
      pageOfItems: [],
      selectedPost: {},
      speakerToIgnoreChecked: false,
      speakerToIgnoreText: "",
      autotaggingChecked: false,
      uploadMedia: false,
      zoomRecordingsList: [],
      zoomRefreshInProgress: false,
      uploadPanel: {
        uploadSnackbar: false,
        items: [
          {
            active: false,
            title: "Uploading files. Please do not refresh the page",
          },
        ],
        timeout: -1,
      },
      uploadAlertText: "",
      uploadDialog: false,
      upfiles: undefined,
      uploadButton: true,
      uploadWarn: {
        snackbar: false,
        vertical: true,
      },
      isUploadingMediaFiles: false,
      isLearnMoreModal: false,
      notetakingCopySnackbar: null,
      transcripts: [],
      cardsList: [],
      lastDocSnapshot: 0,
      firstDocSnapshot: 0,
      cardsRef: "",
      loadingStatus: false,
      refreshState: false,
      prevBtnDisabled: true,
      nextBtnDisabled: false,
      initial: true,
      botInputURL: "",
      botRecordingStatus: false,
      isLoadingBotRecordingDisabled: false,
      isLoadingBotRemovingDisabled: false,
      learnMorePage: false,
      projectDropdownOpened: null,
      sessionsData:[],
      showOngoingZoomCallModal:false,
      showNotetakingDemoModal:false,
      showChoseQuestionScriptModal:false,
      questionScripts:null,
      selectedScript:null,
      clickedStartRecordingFirstTime:true,
      dontShowPopupAgain:true,
      calendarAuthToken:null,
      callsOnPage:4,
      sessions:[],
      selectedProject:null,
      uploadProgress:null,
      projectState:null,
      canSyncCalendar:false,
      calendarSyncInProcess:false,
      showProjectCreatedSnackbar:false,
      showCalendarSyncedSnackbar:false,
      showUploadPulsatingBorder: false,
      showSearchCTA: false,
      cameFromSignup: this.$route.query.cameFromSignup,
      connectCalendarDialog: false,
      showEmailVerifiedSnackbar: false,
      // transcriptProcessed: false,
      // transcriptId:['AIIY5xxBXP1TvNoiVunn','4g9VCR6JNlotVyquWS5T'],
    };
  },
  computed: {
    ...mapState([
      "userProfile",
      "userWorkspace",
      // "transcripts",
      "zoomIntegration",
      "hasZoomSupport",
      "currentRecording",
      // "zoomRecordingsList",
      "subscriptionActive",
      "plan",
      "recordingBot",
      "showUploadModal",
      "trialEnded",
      "showCreateNewProjectModal"
    ]),
    filteredCards() {
      return this.transcripts.filter((p) => {
        return (
          p.fileName.toLowerCase().indexOf(this.search.toLowerCase()) != -1
        );
      });
    },
    botStatus() {
      return this.$store.state.recordingBot.statusName;
    },
    trialEnded() {
      return this.$store.state.trialEnded;
    },
    subscriptionActive(){
      return this.$store.state.subscriptionActive;
    },
    plan(){
      return this.$store.state.plan;
    },
    disableInviteMembers(){
      const trialEnded = this.trialEnded;
      const subscriptionActive = this.subscriptionActive;
      const plan = this.plan;
      // User is on a trial
      if (trialEnded == false && subscriptionActive == false)
        return false

      // User's trial has expired
      if (trialEnded == true && subscriptionActive == false)
        return true

      // User is on an active subscription
      if (trialEnded == true && subscriptionActive == true){
        if (plan == "Solo") return true
        return false  
      }

      // User is on an expired subscription
      if (trialEnded == true && subscriptionActive == false)
        return true

       return false;
    },
  },
  watch: {
    showSearchCTA(latest,old){
      console.log("show search cta changed from",old," to ",latest)
    },
    botStatus(latest, old) {
      this.checkCallStatusNew();
      // console.log({latest})
      // Redirect to notetaking if current status is joining_call, in_call_recording, in_call_not_recording
      if (latest == "joining_call" || latest == "in_call_recording" || latest == "in_call_not_recording") {
        this.isLoadingBotRecordingDisabled = false;
        this.$router.push(`notes/${this.userProfile.notetaking_room_id}`);
      } else if (latest == ("in_waiting_room")) {
        this.isLoadingBotRemovingDisabled = false;
      }else if(latest==("error")){
        this.showBotRecordingError=true;
        setTimeout(()=>{
          this.showBotRecordingError=false;
          this.$store.commit('setBotStatus',{ id:null, status:false, statusName:null});
        },3000)
      }
    },
  },
  // updated() {
  //   this.upfiles = null;
  // },
  methods: {

  // This methods fetches the latest emailVerified field from the user doc and updates it in the store if needed
  async checkEmailVerificationStatus(){
    const emailVerifiedResponse = await UsersAPI.getUserEmailVerified()
    if(emailVerifiedResponse.status == 'success'){
      const emailVerifiedStatus = emailVerifiedResponse['emailVerified']
      console.log('emailVerifiedStatus',emailVerifiedStatus)
      // Store's emailVerified field is different from the one in db, update the one in store
      if(this.userProfile.emailVerified != emailVerifiedStatus){
        this.$store.dispatch("updateUserProfileEmailVerifiedStatus",emailVerifiedStatus)
      }
    }
  },
  closeEmailVerificationModal(){
    console.log('close verification modal ',auth.currentUser)
    localStorage.setItem('hideEmailVerificationModal',true);
    window.analytics.track("close Email verification modal homepage", {
        email: auth.currentUser.email
    });
  },
  async verifyEmail(){
    // console.log('VERIFY EMAIL FN: inside fn')
      if(auth.currentUser){
        // console.log('VERIFY EMAIL FN: user is authenticated')
        if(auth.currentUser.emailVerified == true){
          const emailVerified = await UsersAPI.verifyUserEmail()
          // console.log('VERIFY EMAIL FN: user auth is verified',emailVerified)
          if(emailVerified.status==='success'){
            // console.log('Email Verified: ',emailVerified)
            if(emailVerified.data){
              // console.log('VERIFY EMAIL FN: user doc is verified',emailVerified)
              window.analytics.track("Email verification from homepage", {
                  email: auth.currentUser.email
              });
              // console.log('VERIFY EMAIL FN: will show verified snackbar')
              this.showEmailVerifiedSnackbar = true;
              setTimeout(() => this.showEmailVerifiedSnackbar=false,1500)
            }
            this.$store.dispatch('toggleEmailVerificationBanner',false)
          }
        }else{
          // console.log('VERIFY EMAIL FN: user auth is not verified')
          const hideEmailVerificationModal = localStorage.getItem('hideEmailVerificationModal')
          // console.log('VERIFY EMAIL FN: from local storage: ' + hideEmailVerificationModal)
          if(!hideEmailVerificationModal){
            // console.log('VERIFY EMAIL FN: showing verification modal')
            // this.$refs.verifyEmailModalRef.showModal = true;
            // const continueUrl ="https://staging-looppanel--pr249-team-v1-workspace-qk0yx8tx.web.app/?emailVerified=true"
            const continueUrl = 'https://app.looppanel.com/?emailVerified=true'
            // const continueUrl = 'http://localhost:8080?emailVerified=true';
            let receiverEmail  = auth.currentUser.email;
            let emailType = "verify_email";

            const response = await TransactionalEmailAPI.triggerTransactionalEmail(receiverEmail, emailType, continueUrl);
            console.log("verify email", response)
            
            localStorage.setItem('hideEmailVerificationModal',true);
              window.analytics.track("trigger Email verification email homepage", {
                  email: auth.currentUser.email
            });

            // auth.currentUser.sendEmailVerification({
            //       url: continueUrl
            // })
            // .then(() => {
            //   // Email verification sent!
            //   // console.log('VERIFY EMAIL FN: triggered verification email')
            //   localStorage.setItem('hideEmailVerificationModal',true);
            //   window.analytics.track("trigger Email verification email homepage", {
            //       email: auth.currentUser.email
            //   });
            //   // ...
            // });
            // this.$store.dispatch('toggleEmailVerificationBanner',false)
          }else{
            console.log('VERIFY EMAIL FN: showed verification modal already')
            this.$store.dispatch('toggleEmailVerificationBanner',true)
          }


        }
      }
    },
    clickedOnGoogleSignIn(){
      window.analytics.track("Link Google Account to Verify from homepage", {
          email: auth.currentUser.email
      });
    },
    joinWaitlist(){
      window.analytics.track("click on Join Waitlist for Search on Search Opt-in CTA from homepage", {
        email: auth.currentUser.email,
      });
      console.log('joinwaitlist set show search cta to false',this.showSearchCTA)
      this.showSearchCTA = false;
      localStorage.setItem('showSearchCTA',false)
    },
    checkShowSearchCTA(){
      if(localStorage.showSearchCTA == undefined){
        setTimeout(()=>{
          this.showSearchCTA = true;
        },5*60*1000)
      }
    },
    dismissSearchCTA(){
      window.analytics.track("click on close on Search Opt-in CTA from homepage", {
        email: auth.currentUser.email,
      });
      console.log('dismiss cta set show search cta to false',this.showSearchCTA)
      this.showSearchCTA = false;
      if(localStorage.searchCTADismissed != undefined){
        let searchCTADismissed = JSON.parse(localStorage.getItem("searchCTADismissed"))
        if(searchCTADismissed>=2){
          localStorage.setItem("showSearchCTA",false)
        }else{
          searchCTADismissed += 1;
          localStorage.setItem("searchCTADismissed",JSON.stringify(searchCTADismissed))
        }
      }else{
        localStorage.setItem("searchCTADismissed",JSON.stringify(1))
      }
    },
    trackUploadCallClick(){
      window.analytics.track(this.showUploadPulsatingBorder ? "click on Upload Call with nudge from homepage" :"click on Upload Call from homepage", {
        email: auth.currentUser.email,
      });
    },
    async checkUploadCallNudge(){
      const uploadCallNudge = await UsersAPI.getNudgeCount('Uploaded Calls');
      if(uploadCallNudge.status==='success'){
        const { uploadCount } = uploadCallNudge;
        if(uploadCount<2) this.showUploadPulsatingBorder = true;
        else this.showUploadPulsatingBorder = false;
      }
    },
    async syncCalendar(){
      window.analytics.track("refresh calendar from home view", {
          email: auth.currentUser.email,
      });
      this.calendarSyncInProcess=true;
      const refreshedCalendarState=await CalendarAPI.refreshCalendarMeetings(this.calendarAuthToken);
      this.showCalendarSyncedSnackbar=true;
      setTimeout(()=>{
        this.showCalendarSyncedSnackbar=false;
      },1500)
      // console.log({refreshedCalendarState});
      this.calendarSyncInProcess=false;
    },
    handleToggleCreateNewProjectModal(){
      window.analytics.track("open project creation modal in home view", {
        email: auth.currentUser.email,
      });

      // this.showCreateNewProjectModal=!this.showCreateNewProjectModal;
      this.$store.dispatch('toggleCreateNewProjectModal',!this.showCreateNewProjectModal)
    },
    async createProject(newProject){
      //console.log('createProject fired');
      window.analytics.track("create new project in home view", {
        email: auth.currentUser.email,
      });

      try {
        // console.log('created new project',{newProject})
        // this.projects.splice(1, 0, newProject);
        this.projectState = {
          type: "Create",
          status: true,
          message: "Project Created successfully",
        };
        this.$refs.recentProjectsRef.getRecentProjects();
        this.showProjectCreatedSnackbar=true;
        setTimeout(()=>{
          this.showProjectCreatedSnackbar=false;
        },1500)
        setTimeout(() =>{
          this.$router.push(`/project/${newProject.id}/script`)
        },1500)
      } catch (error) {
        console.log({ error });
        this.projectState = {
          type: "Create",
          status: false,
          message:
            "Oops! There was some error creating a project. Try again later.",
        };
      }
    },
    createNewProject(newProject){
      this.allProjects={
        ...this.allProjects,
        projects:[newProject,...this.allProjects.projects]
      }
      this.recentCallsProjects={
        ...this.recentCallsProjects,
        projects:[newProject,...this.recentCallsProjects.projects]
      }      
    },
    // handleOutsideClick(e){
    //   return;
    // },
    throwError(err) {
      Sentry.setContext("user", {
        id: auth.currentUser.uid,
        email: auth.currentUser.email
      })

      Sentry.captureException(err);
    },
    getFormattedMeetingTime(){
      if(this.latestMeeting.start_time && this.latestMeeting.end_time)
        // return CalendarUtils.extractDateFromMeeting(this.latestMeeting.start_time,this.latestMeeting.end_time)
      return formatDistance(new Date(this.latestMeeting.start_time), new Date(), { addSuffix: true }).replace("about", "");
      else if(this.latestMeeting.start_time && !this.latestMeeting.end_time)
        return format(new Date(this.latestMeeting.start_time), "PPp")
    },
       async getCalendarAuthToken(){

      try{
        const calendarAuthResponse=await CalendarAPI.getCalendarAuthToken(auth.currentUser.uid);
       
        if( calendarAuthResponse.data && 
            calendarAuthResponse.data.status==='error' && 
            calendarAuthResponse.data.type==='GET'
          ){
            this.fetchNewCalendarAuthToken=true;
            const calendarAuthTokenResponse=await CalendarAPI.getNewCalendarAuthToken(auth.currentUser.uid);
            this.calendarAuthToken=calendarAuthTokenResponse.data.token;
          }
        else{ 
          this.calendarAuthToken=calendarAuthResponse.data;
          await this.getCalendarUser();
        }
      }catch(error){
        console.log({error})
      }

    },
    async getCalendarUser(){
      // console.log(this.calendarAuthToken)
      const calendarUserResponse=await CalendarAPI.getCalendarUser(this.calendarAuthToken)
      // console.log(calendarUserResponse)
      if(calendarUserResponse.status==='error'){
        this.calendarPageLoading=false;
        this.fetchNewCalendarAuthToken=true;
      }else{
        const { calendarUser }= calendarUserResponse;

        if(calendarUser.data.connections[0].connected){
          this.canSyncCalendar=true;
          //Already connected to google, fetch calendar meetings
          this.showNewFeatureCard=false;
          // this.checkCallStatusNew()
        }else{
          this.canSyncCalendar=false;
          // this.callsOnPage=8;
          this.showNewFeatureCard= true;
          // this.checkCallStatusNew()
          // console.log('val->',await this.checkCallStatus())
          // if(await this.checkCallStatus()!=true) this.showNewFeatureCard=true;
          
        }
      }
      this.checkCallStatusNew()
     
    },
    async checkCallStatusNew(){
      // console.log("reached check")
      let meetings=[];
      if (this.showNewFeatureCard != true){
            const calendarMeetingsResponse=await CalendarAPI.getCalendarMeetings(this.calendarAuthToken);
            if (calendarMeetingsResponse.status === 'success'){
              // console.log('inside card')
              meetings=calendarMeetingsResponse.calendarMeetings.data;
              this.checkLatestMeeting(meetings)
              // this.refreshList(1)
            } 
            // else {
            //   meetings = []
            //   // Get new token and check latest meeting
            //   // this.getCalendarUser()
            //   // this.checkCallStatusNew()
            // }
      } 
      if(this.recordingBot.status){
      // console.log(this.botStatus)
      // Recording now
      // check status
      if(this.botStatus==='in_call_recording'){
                //Show a call status card
                this.showCallStatusCard=true;

                //Update information on the type of status card
                // this.callsOnPage=8;

                //Update information on the type of status card
                this.callStatus={
                  status:'ongoing',
                  statusText:'Recording Started'   
                }
                // console.log('callstatus->',this.callStatus)

                this.latestMeeting={
                  title:this.currentRecording.payload.object.topic,
                  start_time:this.currentRecording.payload.object.start_time,
                }       

                //Auto record ongoing call status card will be shown => we wont check other cases
                return true;

                
        } else if(this.botStatus==='joining_call'){
          // This is needed to show the card for ad hoc calls 
                         let _latestMeeting=null;
                          for (let i=0; i<meetings.length; i++){
                            if (meetings[i].bot_id == this.recordingBot.id){
                              _latestMeeting = meetings[i]
                            }
                          }
                          //Update the latest meeting state variable => now this latest meeting will be used for other status cards
                          this.latestMeeting=_latestMeeting
                           // Add a check for source if it's calendar skip setting card status                  

                          //Show a call status card
                          this.showCallStatusCard=true;
        
                          //Update information on the type of status card
                          // this.callsOnPage=8;
        
                            //Update information on the type of status card
                          this.callStatus={
                            status:'waiting',
                            statusText:
                              'The notetaker is trying to join your call. Please let it in'
                          }
                          // console.log(this.latestMeeting)
                          if (this.latestMeeting == null) {
                          this.latestMeeting = {
                            title: '',
                            start_time: 0
                          }
                        }
                          return true;
               
        }
      else if(this.botStatus==='in_waiting_room' || this.botStatus==='in_call_not_recording'){
                    // Add a check for source if it's calendar skip setting card status                  

                          //Show a call status card
                          this.showCallStatusCard=true;
        
                          //Update information on the type of status card
                          // this.callsOnPage=8;
        
                            //Update information on the type of status card
                          this.callStatus={
                            status:'waiting',
                            statusText:
                            'Looppanel’s notetaker is trying to join your call. Please let it in.'
                          }
                          let _latestMeeting=null;
                          for (let i=0; i<meetings.length; i++){
                            if (meetings[i].bot_id == this.recordingBot.id){
                              _latestMeeting = meetings[i]
                            }
                          }
                          //Update the latest meeting state variable => now this latest meeting will be used for other status cards
                          this.latestMeeting=_latestMeeting
                          if (this.latestMeeting == null) {
                          this.latestMeeting = {
                            title: '',
                            start_time: 0
                          }
                        }
                          return true;
        
                      // }
                      
                  }
      
      } else {
        if (this.botStatus==='error'){
                //Show a call status card
                this.showCallStatusCard=true;

                //Update information on the type of status card
                // this.callsOnPage=8;

                  //Update information on the type of status card
                this.callStatus={
                  status:'error',
                  statusText:
                    'Looppanel Notetaker wasn’t able to join the ongoing zoom call'
                }

                this.latestMeeting={
                    title:'',
                    start_time:0,
                  }   

                return true;

            // }
            
        } else if (this.botStatus==='done'){
                // Don't Show a call status card
                this.showCallStatusCard=false;
                // this.callsOnPage=9;

                this.checkLatestMeeting(meetings)

                return true;

            // }
            
        }
      }


    },
    // Replaced by checkCallStatusNew()
    async checkCallStatus(){

      //Get current recording state of the call
      const recordingState=this.checkRecordingState();

      //Fetch all the meetings from the /meetings endpoint
      const calendarMeetingsResponse=await CalendarAPI.getCalendarMeetings(this.calendarAuthToken);

      // console.log(this.botStatus,{recordingState},{calendarMeetingsResponse})

      //store the list of meetings fetched
      let meetings;


       if(calendarMeetingsResponse.status==='success'){

        //poplate meetings from the API call
        meetings=calendarMeetingsResponse.calendarMeetings.data;

        /** CASE 1: Ongoing call => either adhoc notetaking or auto recording */
        //First we'll check if there is an ongoing call => Ongoing call will always take preference over all other cases
         if(recordingState){
              // console.log('CASE 1: Ongoing call => either adhoc notetaking or auto recording')
              //Show a call status card
              this.showCallStatusCard=true;

              //Set call card pagination limit to 8 because now status card appears as the first card
              // this.callsOnPage=8;

              //Update information on the type of status card
              this.callStatus={
                        status:'ongoing',
                        statusText:'Recording Started'   
              }

            //For ongoing call, meeting information will be taken from the current recording object
              this.latestMeeting={
                title:this.currentRecording.payload.object.topic,
                start_time:this.currentRecording.payload.object.start_time,
              }      

            
              // console.log('callstatus->',this.callStatus)


            
            //We don't need to check other cases for status card as ongoing call gets #1 priority
            return true; 
            
        }
        
        //Compute latest meeting
        let _latestMeeting=null;

        //Loop through all the meetings list
        for(let i=0; i<meetings.length; i++){
          // console.log({diff},meetings[i].title)

          //Consider meetings that have call information => valid zoom meeting & is set to auto record
          if(meetings[i].platform && meetings[i].will_record ){
            // console.log('latest meeting->',meetings[i])

            //This is the latest meeting so we store it somewhere
            _latestMeeting=meetings[i];
            break;
          }
        }

        //Update the latest meeting state variable => now this latest meeting will be used for other status cards
        this.latestMeeting=_latestMeeting

        /*** CASE 2: Ongoing call that's set to auto record  */
        if(this.botStatus==='in_call_recording'){
              // console.log('CASE 2: Ongoing call thats set to auto record')
              // console.log('meetings->',this.currentRecording,this.latestMeeting)

              //The latest meeting is auto record and bot inside it is the same bot from the current recording document
              if(this.currentRecording.bot_id==this.latestMeeting.bot_id && this.latestMeeting.will_record){
                // console.log('show call status card')

                //Show a call status card
                this.showCallStatusCard=true;

                //Update information on the type of status card
                // this.callsOnPage=8;

                //Update information on the type of status card
                this.callStatus={
                  status:'ongoing',
                  statusText:'Recording Started'   
                }
                // console.log('callstatus->',this.callStatus)

                

                //Auto record ongoing call status card will be shown => we wont check other cases
                return true;

              }
                
        }
        // console.log('reached here with latestMeeting',this.latestMeeting)
        if(this.latestMeeting && this.latestMeeting.will_record){
          
            /** CASE 3: Recording Error call card for auto record calls */
            //Check recording status 
            if(this.botStatus==='in_waiting_room'|| this.botStatus==='error'){
              // console.log('CASE 3: Recording Error call card for auto record calls',this.currentRecording.bot_id,this.latestMeeting)

              //The latest meeting is auto record and bot inside it is the same bot from the current recording document
              // if(this.currentRecording.bot_id==this.latestMeeting.bot_id && this.latestMeeting.will_record){
                              
                  //Show a call status card
                  this.showCallStatusCard=true;

                  //Update information on the type of status card
                  // this.callsOnPage=8;

                   //Update information on the type of status card
                  this.callStatus={
                    status:this.botStatus==='in_waiting_room'?'waiting':'error',
                    statusText:this.botStatus==='in_waiting_room'? 
                      'Looppanel’s notetaker is trying to join & record your call. Please let it in or start your meeting!'
                      :'Looppanel Notetaker wasn’t able to join the ongoing zoom call'
                  }


                  return true;

              // }
             
          }else{
                  /*** CASE 4: Upcoming call card for auto record calls */
                  // console.log('CASE 4: Upcoming call card for auto record calls')
                  return this.checkLatestMeeting(meetings)
          }  
        }

      }


    },
    async checkLatestMeeting(meetings){
        //Take out the latest meeting that is set to auto record
        let _latestMeeting=null;
        for(let i=0; i<meetings.length; i++){
          const diff=differenceInMinutes(new Date(meetings[i].start_time),new Date())
          // console.log({diff},meetings[i].title)
          if(diff>0 && meetings[i].will_record){
            // console.log('latest meeting->',meetings[i])
            _latestMeeting=meetings[i];
            break;
          }
        }

        if (_latestMeeting == null){
          return true
        }
        //Check if it falls within the next hour or so

        this.latestMeeting=_latestMeeting
        // console.log(this.latestMeeting)
        const difference=differenceInMinutes( new Date(this.latestMeeting.start_time),new Date());
        // console.log({difference})
        if(difference<=60 && difference>=0){
          this.showCallStatusCard=true;
          // this.callsOnPage=8;
          this.callStatus={
            status:'upcoming',
            statusText:'Upcoming Call'   
          }
        }
      return true;
    },
    goToNotetakingLink(){
      this.trackOpenNotesEvent()
      this.$router.push(`/notes/${this.userProfile.notetaking_room_id}`)
    },
    toggleConnectCalendarModal(){
      this.connectCalendarDialog = !this.connectCalendarDialog;
      console.log("toggle connect calendar modal",this.connectCalendarDialog)
      // Disable this analytics event as we no longer redirect to calendar page
      // window.analytics.track(`open calendar view from new launch card`, {
      //     email: auth.currentUser.email,
      // });
      //this.$router.push('/calendar')
    },
    async checkOngoingCallPopupState(){

      if(sessionStorage.showOngoingCallPopup){
          const showOngoingCallPopup=JSON.parse(sessionStorage.getItem('showOngoingCallPopup'));
          // console.log({showOngoingCallPopup});
          // this.clickedStartRecordingFirstTime=false;
          // this.dontShowPopupAgain=true;
      }else{
        const botSessions=await sessionsAPI.getAllBotRecordedSessions();
        // console.log({botSessions})
        if(botSessions.status==='success'){
          if(botSessions.sessions.length>2){
            //dont show the ongoing call popup
            this.clickedStartRecordingFirstTime=false;
            this.dontShowPopupAgain=true;
            // sessionStorage.setItem('showOngoingCallPopup',false);
          }else{
            //show the ongoing call popup
            this.clickedStartRecordingFirstTime=true;
            this.dontShowPopupAgain=true;
          }
        }
      }


    },
    async startRecording(){
      if(this.dontShowPopupAgain) this.clickedStartRecordingFirstTime=false;
      this.getQuestionScripts()
    },
    async getAllSessionsForSearch(){
      const sessionsData=await sessionsAPI.getAllSessionsForUser();
      if(sessionsData.status==='success'){
        this.sessions=sessionsData.sessions;
      }else{
        console.log('error',sessionsData)
      }
    },
    disableSession(sessionId) {
      this.disabledSessions.push(sessionId);
    },
    enableSession(sessionId) {
      this.disabledSessions = this.disabledSessions.filter(
        (session) => session !== sessionId
      );
    },
    showProjectDropdown(sessionId) {
      if (this.projectDropdownOpened === sessionId) {
        window.analytics.track("close project dropdown menu in sessions view", {
          email: auth.currentUser.email,
        });
        this.projectDropdownOpened = null;
      } else {
        window.analytics.track("open project dropdown menu in sessions view", {
          email: auth.currentUser.email,
        });
        this.projectDropdownOpened = sessionId;
      }
    },
    hideProjectDropdown(sessionId) {
      // console.log("firing hideProjectDropdown");
      this.projectDropdownOpened = null;
    },
    async getAllProjects() {
      console.log('inside getAllProjects',this.userProfile);
      this.allProjects=await projectsAPI.getAllProjectsWithoutLimit(
      this.userProfile.workspace_role && this.userProfile.workspace_role =='editor' ? this.userProfile.workspace_id : null
      )
      this.recentCallsProjects = await projectsAPI.getAllProjectsWithoutLimit(
     this.userProfile.workspace_id 
      )
    },
    async getDefaultProject() {
      this.defaultProject = await projectsAPI.getDefaultProject();
      this.$store.dispatch("setDefaultProject", this.defaultProject);
    },
    refreshSession(data) {
      // console.log('refresh session called')
      const obj = JSON.parse(data);
      const { projectId, sessionId } = obj;
      // console.log('called refreshSession ',{projectId,sessionId})
      this.cardsList = this.cardsList.map((card) => {
        if (card.id === sessionId) return { ...card, projectId: projectId };
        else return card;
      });
      // console.log(this.cardsList)
      this.$forceUpdate();
    },
    toggleQuestionScriptModal(){
      this.showChoseQuestionScriptModal=!this.showChoseQuestionScriptModal;
    },
    async choseQuestionScript(){
      if (this.botInputURL.length == 0) {
        this.showNotetakingDemoModal=true;
        return;
      }
      if(this.clickedStartRecordingFirstTime){
        this.showOngoingZoomCallModal=true;
      }
      else this.getQuestionScripts()
    },
    
    async getQuestionScripts(){
      const noneScript={id:'1',none:true,name:'None'}
      const response=await QuestionScriptsAPI.getAllScripts(this.userWorkspace.id);
      this.questionScripts=[noneScript,...response.data];
      // console.log('qs',this.questionScripts)
      // this.questionScripts=await QuestionScriptsAPI.getScript_beta();
      if(this.questionScripts.length<=1){ 
        this.$store.commit("setSelectedScriptForNotetaking",noneScript);
         this.addBotToCall();
      }
      else this.showChoseQuestionScriptModal=true;
      // console.log('Open QS modal, select QS then invoke addBotToCall');
      //this.addBotToCall()
    },
    selectScript(_selectedScript){
      this.selectedScript = _selectedScript;
      this.$store.commit("setSelectedScriptForNotetaking",_selectedScript);
      this.addBotToCall()
    },
    // Handles bot addition to a meeting
    addBotToCall() {
      if (this.botInputURL.length == 0) {
        return;
      }
      this.$store.commit("setCurrentRecording", {});
      this.isLoadingBotRecordingDisabled = true;
      let postParams={
        meeting_url: this.botInputURL,
        type: "ADD",
        user_id: auth.currentUser.uid,
      }
      if(!this.selectedScript || this.selectedScript.none){
        // console.log('send nothing')
      }else{
        postParams['script_id']=this.selectedScript.scriptId
      }
      // console.log({postParams: postParams})
      // Call backend function from here with the URL
      var data = JSON.stringify(postParams);

      var config = {
        method: "post",
        url: "https://us-central1-poc-project-309214.cloudfunctions.net/invite_bot",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      
      axios(config)
        .then((response) => {
          // console.log('a',response.data.id);
          // console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          this.isLoadingBotRecordingDisabled = false;
          console.log(error);
          this.$store.commit("setSelectedScriptForNotetaking", null);
          alert(
            "Couldn't start recording, Please check the meeting link and try again"
          );
          this.throwError(error);
        })
        .finally(() => {
          this.botInputURL = "";
        });
    },
    // Handles bot removal from a meeting
    removeBotFromCall() {
      this.isLoadingBotRemovingDisabled = true;
      // Call backend function to remove bot from call using meeting url
      var data = JSON.stringify({
        bot_id: this.recordingBot.id,
        type: "REMOVE",
      });

      var config = {
        method: "post",
        url: "https://us-central1-poc-project-309214.cloudfunctions.net/invite_bot",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      // console.log({config})
      axios(config)
        .then((response) => {
          // console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          this.throwError(error);
          // console.log(error);
          // this.isLoadingBotRemovingDisabled = false;
          // console.log(error);
          // alert("Couldn't stop recording, Please try again or remove the notetaker from meeting")
        })
        .finally(() => {
          this.botInputURL = "";
        });
    },
    refreshList(defaultPage = null) {
      // console.log('calling refreshList')
      const _page = defaultPage;
      this.lastDocSnapshot = 0;
      this.firstDocSnapshot = 0;
      this.cardsList = [];
      this.next(_page);
    },
    goBackToPage1() {
      this.refreshList(1);
    },
    scroll() {
      window.onscroll = () => {
        let bottomOfWindow =
          Math.max(
            window.pageYOffset,
            document.documentElement.scrollTop,
            document.body.scrollTop
          ) +
            window.innerHeight ===
          document.documentElement.offsetHeight;
        // if (bottomOfWindow) {
        //   console.log("fetch transcript data")
        //   if(!this.loadingStatus)
        //   {this.fetchTranscripts()}
        // }
      };
    },
    // handles next click action to fetch docs from firestore
    async next(defaultPage = null) {
      // console.log("called next to fetch docs from firestore",this.callsOnPage);
      this.cardsRef = transcriptsCollection
        .where("userId", "==", auth.currentUser.uid)
        .orderBy("createdOn", "desc")
        .limit(this.callsOnPage);
      if (this.lastDocSnapshot == undefined) {
        this.loadingStatus = false;
        return;
      }
      if (this.lastDocSnapshot) {
        this.cardsRef = this.cardsRef.startAfter(this.lastDocSnapshot);
      }
      this.loadingStatus = true;

      const snap = await this.cardsRef.get();
      this.lastDocSnapshot = snap.docs[snap.docs.length - 1];
      this.firstDocSnapshot = snap.docs[0];
      const result = snap.docs.map((a) => {
        return { ...a.data(), id: a.id };
      });
      // Remove processed_transcript after transcript migration
      result.forEach(function (v) {
        delete v.processed_transcript;
        delete v.timestamp_text_map;
      });
      this.cardsList.splice(0, this.cardsList.length);
      this.cardsList.push(...result);
      this.loadingStatus = false;
      // console.log(this.cardsList)

      if (defaultPage) this.pageNumber = defaultPage;
      else this.pageNumber++;
      //@Gunadhya this is buggy, will need to think of something else
      // if(snap.size <8) {
      //   this.nextBtnDisabled = true;
      //   return
      // }
      transcriptsCollection
        .where("userId", "==", auth.currentUser.uid)
        .orderBy("createdOn", "desc")
        .limit(this.callsOnPage)
        .startAfter(this.lastDocSnapshot)
        .get()
        .then((snap) => {
          // console.log(snap.docs, snap.size);
          if (snap.size === 0) {
            this.prevBtnDisabled = false;
            this.nextBtnDisabled = true;
          } else {
            if (this.initial) {
              // console.log("Initial snap");
              this.prevBtnDisabled = true;
              this.initial = false;
            } else {
              // console.log("Last condition");
              this.prevBtnDisabled = false;
            }
            this.nextBtnDisabled = false;
          }
        });
    },
    // handles previous click action to fetch docs from firestore
    async prev() {
      this.cardsRef = transcriptsCollection
        .where("userId", "==", auth.currentUser.uid)
        .orderBy("createdOn", "desc")
        .limitToLast(this.callsOnPage);
      if (this.firstDocSnapshot == undefined) {
        this.loadingStatus = false;
        return;
      }
      if (this.firstDocSnapshot) {
        this.cardsRef = this.cardsRef.endBefore(this.firstDocSnapshot);
      }
      this.loadingStatus = true;

      const snap = await this.cardsRef.get();

      this.lastDocSnapshot = snap.docs[snap.docs.length - 1];
      this.firstDocSnapshot = snap.docs[0];
      const result = snap.docs.map((a) => {
        return { ...a.data(), id: a.id };
      });
      // Remove processed_transcript after transcript migration
      result.forEach(function (v) {
        delete v.processed_transcript;
        delete v.timestamp_text_map;
      });
      this.cardsList.splice(0, this.cardsList.length);
      this.cardsList.push(...result);
      this.pageNumber--;
      this.loadingStatus = false;
      transcriptsCollection
        .where("userId", "==", auth.currentUser.uid)
        .orderBy("createdOn", "desc")
        .limitToLast(this.callsOnPage)
        .endBefore(this.firstDocSnapshot)
        .get()
        .then((snap) => {
          if (snap.size === 0) {
            this.prevBtnDisabled = true;
            this.nextBtnDisabled = false;
          } else {
            this.prevBtnDisabled = false;
            this.nextBtnDisabled = false;
          }
        });
    },
    // async fetchTranscripts(){
    //   let ref = transcriptsCollection.where("userId", "==", auth.currentUser.uid).orderBy('createdOn', 'desc').limit(11);
    //   if (this.lastDocSnapshot == undefined) {
    //     this.loadingStatus = false;
    //     return;
    //   }
    //   if (this.lastDocSnapshot) {
    //     ref = ref.startAfter(this.lastDocSnapshot);
    //   }
    //   this.loadingStatus = true

    //   const snap = await ref.get();
    //   this.lastDocSnapshot = snap.docs[snap.docs.length-1];
    //   const result = snap.docs.map((a)=>{
    //     return { ...a.data(), id: a.id };
    //   })
    //   result.forEach(function(v){
    //     delete v.processed_transcript
    //     delete v.timestamp_text_map
    //   });
    //   this.transcripts.push(...result)
    //   // this.transcripts2.push(...result)

    //   this.loadingStatus = false

    // },
    copyNotesUrl() {
      navigator.clipboard.writeText(
        `${window.origin}/notes/${this.userProfile.notetaking_room_id}`
      );

      this.notetakingCopySnackbar = true;

      window.analytics.track("copy notes URL from sessions view", {
        email: auth.currentUser.email,
      });
    },
    checkRecordingState() {
      // console.log("rec", this.currentRecording);
      if (
        ["recording.started", "recording.resumed", "recording.paused"].includes(
          this.currentRecording.event
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    // removes requested file from selection list in upload dialog
    removeSelectedFile(removeFile) {
      this.selectedFiles = this.selectedFiles.filter(function (v) {
        return v !== removeFile;
      });
    },
    // clears all files from selection list in upload dialog
    clearSelectedFiles() {
      this.selectedFiles = [];
    },
    // closes the upload dialog
    closeUploadDialog() {
      this.uploadDialog = false;
      this.clearSelectedFiles();
    },
    // handles file input via v-file-input and adds them to selection list in upload dialog
    // Limit for number of files in selection list is 5
    handleMediaFilesUpload() {
      // console.log('running ',this.upfiles,this.selectedFiles)
      this.uploadAlertText = "";
      // if(!this.selectedFiles || !this.upfiles ) return;
        if (this.selectedFiles.length < 5 && this.upfiles.length < 5) {
          // console.log('running ',this.upfiles,this.selectedFiles)
          this.upfiles.forEach((file) => {
            this.mediaFiletoUpload.myFile = file;
            this.selectedFiles.push(this.mediaFiletoUpload);
            this.mediaFiletoUpload = {
              myFile: null,
              processing: false,
              fileURL: null,
              progress: null,
            };
          });
          this.upfiles=[];
        } else{
          this.uploadAlertText = "Upload Limit is 5";
        }
      

    },
    // starts uploading files based on selection list
    startMediaFilesUpload(selectedFiles=null,selectedProject=null) {
      // clear the v-file-input and take files to upload from selection list and empty the list
      this.progresslist = selectedFiles || this.selectedFiles;
      this.selectedFiles = [];
      this.selectedProject=selectedProject;
      // Check if the progress list is greater than 0 and less than 5
      if (this.progresslist.length != 0 && this.progresslist.length < 5) {
        this.isUploadingMediaFiles = true;
        this.uploadWarn.snackbar = true;
        var uploadQueue = [];
        // async call to uploadMediaFile() with each file as input and collect all the promises in uploadQueue[]
        this.progresslist.forEach((f) => {
          // console.log('inside progress list loop',this)
          uploadQueue.push(this.uploadMediaFile(f,selectedProject));
        });
        // wait for all the uploads to finish and clean up data and ui(uploadPanel, uploadButton)
        Promise.all(uploadQueue).then((v) => {
          this.progresslist = [];
          this.uploadPanel.uploadSnackbar = false;
          this.isUploadingMediaFiles = false;
        });
        this.uploadDialog = false;
        this.uploadPanel.uploadSnackbar = true;
      } else {
        this.uploadAlertText = "Please add some files to start upload!";
      }
    },
    handleMediaFileUpload(mediaFile) {
      this.mediaFiletoUpload.myFile = mediaFile;
      this.uploadMediaFile();
    },
    async getDocFromTranscripts(id) {
      const snapshot = await transcriptsCollection.doc(id).get();
      const data = snapshot.data();
      delete data.processed_transcript;
      delete data.timestamp_text_map;
      return data;
    },
    async uploadMediaFile(mediaUploadFile,selectedProject) {
      try {
        // Get current user
        const current_user = auth.currentUser;
        // Get the file from the mediaUploadFile object
        var file = mediaUploadFile.myFile;

        if (file && file.name) {
          // Set processing flag to true
          mediaUploadFile.processing = true;

          // Create a storage path for the file with a timestamped filename and metadata
          const filePath = `videos/${Date.now()}-${file.name}`;
          const metadata = { contentType: file.type };

          // Upload file to cloud storage
          var uploadTask = storage.ref().child(filePath).put(file, metadata);
          // eslint-disable-next-line no-console
          uploadTask.on("state_changed", (snapshot)=>{
              // Observe state change events such as progress, pause, and resume
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              var progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              progress=Math.round(progress);

              // Set progress status
              this.uplaodProgress=progress;
              mediaUploadFile.progress =progress;

              // Update progress in the progresslist for the current file
              this.progresslist=this.progresslist.map(p=>p.myFile.name==mediaUploadFile.myFile.name?{...p,progress:progress}:p)
          });

          // Wait for upload to finish
          await uploadTask.then((snapshot) => {
            // Analytics event for project assignment while uploading a media file
            if(selectedProject.default){
              window.analytics.track("media file upload for transcription without project assignment", {
                email: current_user.email,
              });
            }else{
              window.analytics.track("media file upload for transcription with project assignment", {
                email: current_user.email,
              });
            }

            // Get Download Url
            snapshot.ref.getDownloadURL().then(async(downloadURL) => {
              // Create transcript record object with downloadURL as mediaUrl
              const _transcriptDocument = {
                  createdOn: new Date(),
                  url: null,
                  fileName: file.name,
                  userId: current_user.uid,
                  userName: this.userProfile.name,
                  projectId: this.selectedProject.id,
                  tagCount:0,
                  noteCount:0,
                  source:'upload',
                  autoTagging: true,
                  mediaUrl: downloadURL,
                  processed: false,
                  updatedOn: new Date(),
                  workspace_id: this.userWorkspace.id
              }
              // Check if selected project is default or not
              if (this.defaultProject.id != this.selectedProject.id){
                // Get script info for the selected project if available
                const scriptData = await QuestionScriptsAPI.getScriptByProjectId(this.selectedProject.id,true)
                // Assign script id
                if (scriptData != null) {_transcriptDocument.scriptId = scriptData.scriptId}
              }
              // Attach expected speaker selection if not auto
              if (file.defaultSpeakerSelection != 'Auto-detect'){
                _transcriptDocument.speakers_expected = parseInt(file.defaultSpeakerSelection)
              }
              // Add a new transcript record
              transcriptsCollection
                .add(_transcriptDocument)
                .then((document) => {

                  //Update session count in the projects collection
                  // projectsCollection.doc(this.selectedProject.id).update({
                  //       sessionCount: firebase.firestore.FieldValue.increment(1)
                  // })

                  // create payload for process video
                  var payload_process_video = JSON.stringify({
                    session_id: document.id,
                    user_id: current_user.uid,
                  });
                  var config_process_video = {
                    method: "post",
                    url: "https://us-central1-poc-project-309214.cloudfunctions.net/process_video",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    data: payload_process_video,
                  };
                  // Trigger process video
                  axios(config_process_video)
                    .then(function (response) {
                      // JSON responses are automatically parsed.
                      console.log(response);
                    })
                    .catch(function (e) {
                        console.log(e);
                        // Send error log to sentry
                        this.throwError(e);
                    });
                });
            });
          });

          // Reset flags
          this.speakerToIgnoreChecked = false;
          this.speakerToIgnoreText = "";
          this.autotaggingChecked = false;
          this.uploadMedia = false;
        }
      } catch (e) {
        console.error(e);
      } finally {
        mediaUploadFile = {
          myFile: null,
          processing: false,
          fileURL: null,
          progress: null,
        };
      }
    },

    handleFileUpload(transcriptFile) {
      this.filetoUpload.myFile = transcriptFile;

      this.uploadFile();
    },
    deleteTranscript(transcriptId, index) {
      this.$store.dispatch("deleteTranscript", transcriptId);
        this.transcripts.splice(index, 1);
        this.cardsList = this.cardsList.filter(
          (_card) => _card.id != transcriptId
        );
      window.analytics.track("delete session", {
        email: auth.currentUser.email,
      });
    },
    triggerSessionCreationFromRecording(recordingData,projectData) {
      if(projectData.default){
        window.analytics.track("import zoom recording without project assignment", {
          email: auth.currentUser.email,
        });
      }else{
        window.analytics.track("import zoom recording with project assignment", {
          email: auth.currentUser.email,
        });
      }

      // Trigger /download_zoom_recording
      this.$store.dispatch("downloadZoomRecording", {recordingData,projectData});

      return;
    },
    refreshRecordingList() {
      if(!this.zoomIntegration) return;

      window.analytics.track("refresh zoom recording list", {
        email: auth.currentUser.email,
      });

      this.zoomRefreshInProgress = true;

      var data = JSON.stringify({
        user_id: auth.currentUser.uid,
      });

      var config = {
        method: "post",
        url: "https://us-central1-poc-project-309214.cloudfunctions.net/fetch_zoom_recordings_list",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then((response) => {
          // console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.zoomRefreshInProgress = false;
        });

      return;
    },
    isDeleteDisabled(timeVal, processingStatus) {
      let date = new Date(timeVal.seconds * 1000);
      if (differenceInMinutes(date, new Date()) > 15 && !processingStatus) {
        return true;
      } else {
        return false;
      }
    },
    trackOpenTranscriptEvent(transcriptId) {
      window.analytics.track("open transcript", {
        email: auth.currentUser.email,
        transcriptId: transcriptId,
      });
    },
    trackOpenNotesEvent() {
      window.analytics.track("open notetaking view", {
        email: auth.currentUser.email,
      });
    },
    trackBotEvent(type) {
      if (type == "add") {
        window.analytics.track(
          "add bot to call by pasting zoom link in sessions view",
          {
            email: auth.currentUser.email,
          }
        );
      } else if (type == "remove") {
        window.analytics.track("remove bot from sessions view", {
          email: auth.currentUser.email,
        });
      }
    },

    // Disabling onboarding tour & checklist

    persistFirstVisitState(){
      var firstTimeVisit = localStorage.getItem("firstTimeVisit");
      // console.log("firstTimeVisit..",firstTimeVisit)
      if(firstTimeVisit===null){
        sessionStorage.setItem("aiNotesOnboardingStarted", "true")
        localStorage.setItem("onboardingChecklistDisplayed", "false")
        window.analytics.track("auto triggered product onboarding", {
            email: auth.currentUser.email,
        });
        this.launchProductTour()

        //Logic for auto filling demo meeting URL
        // setTimeout(()=>{
        // console.log("input->", document.getElementById("call-recording-input"))
        // document.getElementById("call-recording-input").value= Miscellaneous.OnboardingZoomCallUrl
        // },3000)
        setTimeout(()=>{
          localStorage.setItem("firstTimeVisit",false)
        },3000)
      }

      //Auto trigger onboarding checklist logic
      if(firstTimeVisit==="false" && localStorage.getItem("onboardingChecklistDisplayed")=="false"){
        // console.log("trigger checklist->",firstTimeVisit,localStorage.getItem("onboardingChecklistDisplayed"))
        setTimeout(()=>{
          window.Intercom('startChecklist', 33867602);
          window.analytics.track("auto triggered onboarding checklist on home view", {
            email: auth.currentUser.email,
          });
        },100)
        localStorage.setItem("onboardingChecklistDisplayed", "true")
      }
    },

    launchProductTour(){
      try{
        // console.log("launching home page product tour....");
        // intercom product tour id
        window.Intercom('startTour', 447122);
        sessionStorage.setItem("onboardingComplete",false)
        // setTimeout(()=>{
        //     sessionStorage.setItem("aiNotesOnboardingStarted", "false")
        // },1000)
      }
      catch(err){
        console.log("launch product tour error", err)
      }
    },
  },
  filters: {
    formatMessageRecordingStatus(val) {
      if (!val) {
        return "-";
      }

      let date = new Date(val.seconds * 1000);
      if (differenceInMinutes(date, new Date()) < 15) {
        return (
          "Recording started " +
          formatDistance(date, new Date(), { addSuffix: true })
        ).replace("about", "");
      } else {
        return "error - contact support!";
      }
    },
    formatMessageProcessed(val) {
      if (!val) {
        return "-";
      }

      let date = new Date(val.seconds * 1000);
      // return "uploaded " + moment(date).fromNow();
      return formatDistance(date, new Date(), { addSuffix: true }).replace(
        "about",
        ""
      );
    },
    formatMessageUnprocessed(val) {
      if (!val) {
        return "-";
      }

      let date = new Date(val.seconds * 1000);
      if (differenceInMinutes(date, new Date()) < 15) {
        return (
          "processing - uploaded " +
          formatDistance(date, new Date(), { addSuffix: true })
        ).replace("about", "");
      } else {
        return "error - please upload again or contact support!";
      }
    },
    trimLength(val) {
      if (val.length < 25) {
        return val;
      }
      return `${val.substring(0, 25)}...`;
    },
    formatTimestamp(val) {
      let date = val.toDate();
      // return date.format("MMMM Do, hh:mm");
      return format(date, "MMMM do, hh:mm");
      // toString();
    },
  },
  mounted() {
    // if(!this.userWorkspace) this.$router.push('/workspace')
    console.log('USER-->',auth.currentUser)
    // this.verifyEmail()

    if(localStorage.workspace_to_join!= undefined){
      let redirectUrl = `/workspace`
      const workspaceToJoin = localStorage.getItem('workspace_to_join')
      const inviteeUserId= localStorage.getItem('invitee_user_id')
      const defaultRole = localStorage.getItem('default_role')
      localStorage.removeItem('workspace_to_join')
      localStorage.removeItem('default_role')
      localStorage.removeItem('invitee_user_id')
      redirectUrl = redirectUrl + `/${workspaceToJoin}?action=join&invitee=${inviteeUserId}&workspace_role=${defaultRole}`
      this.$router.push(redirectUrl)
    }

    // Route to complete signup page if company details don't exist in user record
    // console.log('this.userProfile',this.userProfile.company_name)
    console.log('route query',this.$route.query)

    // If URL has the emailVerified parameter and it's value is true
    if(this.$route.query.emailVerified && this.$route.query['emailVerified'] == 'true'){
      // Check if email verification status in store needs to be updated
      this.checkEmailVerificationStatus()
    }

    if(this.cameFromSignup){
      const newURL = '/';
      window.history.pushState({}, '', newURL);
      this.checkShowSearchCTA()
    }

    if(localStorage.searchCTADismissed != undefined){
        let searchCTADismissed = JSON.parse(localStorage.getItem("searchCTADismissed"))
        if(searchCTADismissed>=2){
          localStorage.setItem("showSearchCTA",false)
        }else{
          searchCTADismissed += 1;
          localStorage.setItem("searchCTADismissed",JSON.stringify(searchCTADismissed))
          this.checkShowSearchCTA()
        }
    }


    console.log('local storage redirect',localStorage.redirect_url)

    // This can be used to redirect user to any URL after login/signup
    if(localStorage.redirect_url){
      const redirectUrl=localStorage.getItem('redirect_url')
      console.log('redirectUrl',redirectUrl)
      // Delayed redirect to ensure all dependent state in the store is loaded when user comes via signup
      setTimeout(()=>{
        this.$router.push(redirectUrl)
        localStorage.removeItem('redirect_url');
      },3000)
    }

    // Disaling onboarding tour & checklist
    // this.persistFirstVisitState()

    if(this.$route.query.upload =='true'){
      setTimeout(()=>{
          console.log("opening modal...")
          this.$store.dispatch('toggleUploadModal',true)
      },1000);
      this.$router.replace({ path: '/' })
    }

    if(this.$route.query.upgrade !== undefined){
      setTimeout(()=>{
      this.$store.dispatch('toggleUpgradePlanModal',true)
      },1000);
      this.$router.replace({ path: '/' })
    }

    // if (this.checkRecordingState()){
    //   this.checkCallStatus()
    // }
    // window.addEventListener('click',this.handleOutsideClick);
    this.getCalendarAuthToken()
    this.checkOngoingCallPopupState()
    this.getAllSessionsForSearch();
    this.getDefaultProject();
    this.getAllProjects();
    this.checkUploadCallNudge()
    // console.log("currentRecording", this.currentRecording);
    // this.$store.commit('setCurrentRecording', {});
    window.analytics.identify(auth.currentUser.email, {
      email: auth.currentUser.email,
    });

    // window.analytics.group(this.userProfile["company_id"], {
    //   name: this.userProfile["company_name"],
    // });

    window.analytics.page("Dashboard");

    this.unsubscribeZoomList = zoomRecordingsCollection
      .where("user_id", "==", auth.currentUser.uid)
      .where("file_type", "==", "MP4")
      .onSnapshot((snapshot) => {
        this.zoomRecordingsList = [];

        snapshot.forEach((doc) => {
          let recording = doc.data();

          recording = (({
            zoom_meeting_topic,
            zoom_meeting_duration,
            recording_start_timestamp,
            processing_status,
            zoom_recording_id,
          }) => ({
            zoom_meeting_topic,
            zoom_meeting_duration,
            recording_start_timestamp,
            processing_status,
            zoom_recording_id,
          }))(recording);
          recording.id = doc.id;

          // zoom_meeting_topic, zoom_meeting_duration,
          // recording_start_timestamp, processing_status
          this.zoomRecordingsList.push(recording);
        });

        this.zoomRecordingsList.sort((a, b) => {
          if (a.recording_start_timestamp < b.recording_start_timestamp)
            return 1;
          if (a.recording_start_timestamp > b.recording_start_timestamp)
            return -1;
          return 0;
        });
      });

    // // Initialize cardList with values
    // this.next();
    
    // Listen to changes for most recent calls and update modified cards
    this.unsubscribeTranscriptChanges = transcriptsCollection
      .where("userId", "==", auth.currentUser.uid)
      .orderBy("createdOn", "desc")
      .limit(12)
      .onSnapshot((snapshot) => {
        // console.log('inside transcripts listener Dashboard')
        snapshot.docChanges().forEach((change) => {
            // console.log('change',change,change.doc.data())
            if (change.type === "added") {
                // console.log("Added: ", change.doc.data());
                this.refreshList();
            }
            if (change.type === "modified") {
                console.log("Modified: ", change.doc.data());
                const doc_id = change.doc.id
                this.cardsList = this.cardsList.map((card) => {
                  if (card.id === doc_id)
                    return { ...change.doc.data(), id: doc_id };
                  else return card;
                });
            }
            if (change.type === "removed") {
                // console.log("Removed: ", change.doc.data());
                this.refreshList();
            }
        });
      });
  },
  beforeDestroy() {
    // console.log("beforeDestroy called")
    this.unsubscribeZoomList();
    this.unsubscribeTranscriptChanges();
    this.cardsList = [];
  },
  deactivate() {
    this.unsubscribeZoomList();
    this.unsubscribeTranscriptChanges();
    this.cardsList = [];
  },
};
</script>

<style lang="scss" scoped>

/** ------------- START OF MEDIA QUERIES  ------------------ */

// For large screens dont display the recording UI on the page because it's there on the navbar
@media only screen and (min-width: 800px){
  .start-recording-container{
    display: none;
  }
}

// For small screens, display the recording UI on the page since it's not there on the navbar
@media only screen and (max-width: 799px){
  .start-recording-container{
    display: block;
    margin-bottom: 50px;
  }
  .dashboard-action-row{
    margin-top:64px;
  }
}

/** ------------- END OF MEDIA QUERIES  ------------------ */

#dashboard{
  // margin: 24px 40px;

  //extra top margin needed when annoucement bar is active
  margin: 35px 40px;
}

button{
  outline:none !important;
}

.share-notetaking-link-btn{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 0px;
  gap: 4px;

  width: 306.67px;
  height: 34px;

  /* Neutrals/200 */

  border: 1px solid #EDEDED;
  border-radius: 8px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */

  text-transform: uppercase;

  /* Primary/500 */

  color: #5B7EDB;

}

.share-notetaking-link-btn i{
  border-radius: 8px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */

  text-transform: uppercase;

  /* Primary/500 */

  color: #5B7EDB;
}

.return-to-notetaking-btn{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px 8px 16px;
  gap: 4px;

  width: 306.67px;
  height: 34px;

  /* Primary/500 */

  background: #5B7EDB;
  border-radius: 8px;
  /* Button/Small */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */

  text-transform: uppercase;

  /* Neutrals/White */

  color: #FFFFFF;
}

.return-to-notetaking-btn i{
  /* Button/Small */

font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 12px;
line-height: 150%;
/* identical to box height, or 18px */

text-transform: uppercase;

/* Neutrals/White */

color: #FFFFFF;
}
// .moving i{
//   visibility: hidden
// }

.ongoing-call-text,.ongoing-call-text span{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */
  /* Neutrals/400 */
  color: #999999;
}

.ongoing-call-text span{

  // color: #202124;
  color: #4C4C4C;
}

.empty-call-text{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  /* identical to box height, or 27px */


  /* Neutrals/500 */

  color: #666666;
}

.dim {
  opacity: 0.5;
  cursor: not-allowed;
}

.undim {
  opacity: 1;
}
.status {
  &.open:before {
    background-color: #94e185;
    border-color: #78d965;
    box-shadow: 0px 0px 4px 1px #94e185;
  }

  &.in-progress:before {
    background-color: #ffc182;
    border-color: #ffb161;
    box-shadow: 0px 0px 4px 1px #ffc182;
  }

  &:before {
    content: "";
    display: inline-block;
    width: 7px;
    height: 7px;
    margin-left: 2px;
    margin-right: 4px;
    border: 1px solid #000;
    border-radius: 7px;
  }

  .abbr {
    clear: right;
    /* ... */
  }
}

.start-tooltip {
  background-color: #fff3dc;
  border-radius: 8px;
  border: 1px solid #b7791f;
  opacity: 1 !important;
  padding: 8px;
}

.start-tooltip-content {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 150%;
  color: #b7791f;
  background-color: #fff3dc;
  padding-right: 8px;
}
.learn-more-dialog-outer {
  position: absolute !important;
  background: #fff3dc !important;
  border: 1px solid #e6e6e6 !important;
  box-sizing: border-box !important;
  box-shadow: 1px 1px 10px rgba(102, 102, 102, 0.15) !important;
  border-radius: 12px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.learn-more-dialog-inner {
  position: absolute !important;
  max-width: 243px;
  right: 20%;
  top: 15%;
  background: #fff3dc !important;
  border: 1px solid #e6e6e6 !important;
  box-sizing: border-box !important;
  box-shadow: 1px 1px 10px rgba(102, 102, 102, 0.15) !important;
  border-radius: 12px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dont-show-label{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */

  letter-spacing: -0.01em;

  /* Neutrals/500 */

  color: #666666;
// .v-radio:first-child{
//   margin-bottom:20px !important;
// }
}

.v-input--radio-group {
  margin:0 !important;
}

.question-script-name{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */
  /* Neutrals/500 */
  color: #666666;

}

.dashboard-action-row{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-call-btn{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 12px 16px;
  width: auto;
  height: 45px;
  /* Primary/500 */
  background: #5B7EDB;
  border-radius: 44px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */

  letter-spacing: -0.01em;
  text-transform: capitalize;

  /* Neutrals/White */

  color: #FFFFFF;
}

.add-call-btn i{
  margin-right: 5px;
}
.create-new-project-btn{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 4px;
  width: 181px;
  height: 45px;
  /* Neutrals/100 */
  background: #5B7EDB;
  border-radius: 44px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */

  letter-spacing: -0.01em;
  text-transform: capitalize;

  /* Neutrals/500 */

  color: #fff;
}

.sync-calendar-btn{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 4px;

  // width: 149px;
  height: 45px;

  border-radius: 44px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */

  letter-spacing: -0.01em;
  text-transform: capitalize;

  /* Neutrals/500 */

  color: #666666;
}

.sync-calendar-btn:disabled{
  opacity: 0.5;
}

.connect-calendar-icon{
  color: #5B7EDB;
  margin-right: 5px;
  margin-top: 2px;
}

.connect-calendar-btn{
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  gap: 4px;

  width: 139px;
  height: 29px;

  /* Primary/500 */

  background: #5B7EDB;
  border-radius: 24px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */
  cursor: pointer;

  /* Neutrals/White */

  color: #FFFFFF;
  outline:none;
}

.upcoming-call-icon{
 font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */
  /* Accent/Green/Default */
  color: #33B373;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.upcoming-call-icon i{
  margin-top: 1px;
  margin-right: 2px;
}

.upcoming-call-btn{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  gap: 4px;

  width: 182px;
  height: 29px;

  /* Accent/Green/Default */

  background: #33B373;
  border-radius: 24px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */


  /* Neutrals/White */

  color: #FFFFFF;
}

.waiting-call-icon{
  color: #B7791F;
  margin-top: 2px;
  margin-right: 2px;
}

.ongoing-call-icon{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 8px;
  gap: 4px;

  width: 107px;
  height: 25px;

  /* Accent/Red/Light */

  background: #FFF5F5;
  border-radius: 44px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */


  /* Accent/Red/Default */

  color: #C53030;
}

.ongoing-call-btn{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  gap: 4px;

  // width: 144px;
  height: 29px;

  /* Neutrals/100 */

  background: #F8F8F8;
  border-radius: 24px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */


  /* Primary/500 */

  color: #5B7EDB;
}


</style>
