var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":_vm.width,"persistent":_vm.isPersistent,"content-class":"loop-modal"},on:{"click:outside":function($event){return _vm.$emit('toggle-modal')}},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('v-card',{staticClass:"radius-16",staticStyle:{"overflow-y":"hidden"},attrs:{"height":_vm.height}},[(_vm.showHighlightedInfo)?_c('div',{staticClass:"loop-modal-highlightedInfo"},[_c('div',{staticClass:"highlightedInfo-gif__container",style:({'height':_vm.gifContainerHeight})},[_vm._t("highlightedInfo-gif")],2),_vm._t("highlightedInfo-text"),_vm._t("highlightedInfo-img")],2):_vm._e(),_c('v-card-title',{staticClass:"loop-modal-header mt-0 pt-0 text-h5 d-flex-row",style:({'padding':'16px 20px !important','border-bottom':_vm.showBorder ? '1px solid #EDEDED':'none'})},[_c('p',{class:_vm.isSmallTitle ? 'loop-modal-title-small mb-0' : _vm.isLongTitle ? 'loop-modal-title loop-modal-title__long':'loop-modal-title',style:({'margin':_vm.alignTitle=='center' ? 'auto' : '0'})},[(_vm.hideTitle)?void 0:[_vm._v(_vm._s(_vm.title))]],2),(!_vm.hideCloseButton)?_c('span',{staticClass:"loop-modal-closeBtn",on:{"click":function($event){return _vm.$emit('toggle-modal')}}},[_vm._v(" ✕ ")]):_vm._e()]),_c('v-card-text',{staticClass:"loop-modal-content flex-col mt-0 mb-0 pb-0",staticStyle:{"padding":"0px 20px !important"}},[_vm._t("content"),(_vm.showCta)?_c('div',{staticClass:"loop-modal-ctaActionContainer mt-3 mb-3",style:({'justify-content':_vm.smallCTA ? 'center' : 'space-between'})},[(_vm.showCancelButton)?_c('button',{staticClass:"cancel-btn",style:({
                  'color': _vm.cancelBtnColor ? _vm.cancelBtnColor : '#4C4C4C',
                  'border':`1px solid ${_vm.cancelBtnColor?_vm.cancelBtnColor:'#EDEDED'} !important`,
                  'width' : _vm.fullWidth ? '48%' : '239px'
                }),on:{"click":function($event){return _vm.$emit('toggle-modal')}}},[(_vm.cancelButtonText)?[_vm._v(" "+_vm._s(_vm.cancelButtonText)+" ")]:[_vm._v(" Cancel ")]],2):_vm._e(),_c('v-btn',{class:_vm.showCancelButton ? 'mb-0' : 'mb-0',style:({
                    'font-weight': '600 !important',
                    'border-radius': '20px !important',
                    'height':'40px',
                    'width': _vm.fullWidth ? '48%' : (_vm.showCancelButton || _vm.smallCTA) ? '48%' : '100%',
                    'border':`1px solid ${_vm.ctaBtnBorderColor?_vm.ctaBtnBorderColor:_vm.ctaBtnColor} !important`,
                  }),attrs:{"color":_vm.ctaBtnColor,"dark":"","elevation":"0"},on:{"click":_vm.emitCustomCTAEvent}},[_c('p',{staticClass:"loop-modal-cta mb-0",style:({'color':`${_vm.ctaBtnTextColor} !important`,})},[_vm._v(" "+_vm._s(_vm.ctaText)+" ")])])],1):_vm._e(),(_vm.showCtaHelper)?_c('div',{staticClass:"loop-modal-ctaHelper"},[_vm._t("ctaHelper-template")],2):_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }