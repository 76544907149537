<template>
  <v-dialog 
    v-model="showCreateNewProjectModal" 
    @click:outside="$emit('toggle-modal')" 
    width="480" content-class="create-project-modal">
          <v-card class="create-project-modal__container">

            <v-card-title class="create-project-modal__header text-h5 d-flex-row" >
              
              <p class="create-project-modal-title" >
                  Create new project
              </p>

              <span class="create-project-modal-closeBtn" 
                @click="$emit('toggle-modal')" >
                        &#x2715;
              </span>
            </v-card-title>

            <v-card-text class="create-project-modal__content flex-col mt-4" >
              <div class="project-name-menu">
                  <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <button class="emoji-menu" v-bind="attrs" v-on="on">
                     <span style="font-size:20px">{{selectedEmoji}}</span>
                      <i class="ri-arrow-up-s-line"></i>
                    </button>
                  </template>
                  <div class="emojis-list-container">
                    <span @click="selectedEmoji=emoji" :key="emoji" v-for="emoji in emojis">{{emoji}}</span>
                  </div>
                </v-menu>
                <!-- <input @keypress.enter="createNewProject" v-model="projectName" required="true" class="main-input" placeholder="Enter project name" /> -->
                <!-- Disable submit on enter on project creation modal required for onboarding tour -->
                <input v-model="projectName" required="true" class="main-input" placeholder="Enter project name" />

              </div>
 
              <div class="create-project-modal-actionContainer">
                  
              <v-btn
                color="#5b7edb"
                :class="projectName && !projectCreationLoading ? 'enable' :'disable'"
                dark
                @click="createNewProject"
                style="
                  font-weight: 600 !important;
                  border-radius: 12px !important;
                  height:40px;
                  width:100%;
                "
                elevation="0"
              >

              <!-- //!Remove inline styling   -->
                <p class="create-project-modal-actionBtn mb-0 white--text">
                  <template v-if="projectCreationLoading">
                    Creating your project...
                    <v-progress-circular indeterminate color="white" :size="16" :width="2" ></v-progress-circular>
                  </template>
                  <template v-else>
                    Create Project       
                  </template>
                </p>
                
              </v-btn>
              </div>
              
            </v-card-text>
          </v-card>
        </v-dialog>
</template>


<script>
import projectsAPI from '@/api/projects/projects';
import { mapState } from "vuex";

export default{
    computed: {
      ...mapState([
        "userProfile",
        "userWorkspace",
      ]),
    },
    props:{
        showCreateNewProjectModal:{
            type: Boolean,
            required: true,
        },
    
    },
    /** STATE */
    data(){
        return{
            projectName:'',
            emojis:[
              '⚽️','🎉','✨','🧠','👋','✌️','👀','🎾','💻','👾','📱','💊','💡','🗝','💰'
            ],
            selectedEmoji:'⚽️',
            projectCreationLoading:false

        }
},

/** FUNCTIONS and HANDLERS */
methods:{

    async createNewProject(){
      if(!this.projectName) return;
      if(this.projectCreationLoading) return;
      this.projectCreationLoading=true;
      const newProject = await projectsAPI.createNewProject(this.projectName,this.userWorkspace.id,this.userProfile.name,this.selectedEmoji);
      this.projectCreationLoading=false;
      this.$emit('create-project',newProject);
      this.$emit('toggle-modal')
      this.projectName='';
      return;
    },

 },

 /** LIFECYCLE METHODs */

}

</script>

<style >

    .create-project-modal,.create-project-modal__container{
      border-radius: 16px !important;
    }
    .create-project-modal__container{
      padding: 24px;
    }

    .create-project-modal__header,.create-project-modal__content{
      padding:0px !important;
    }
    .create-project-modal-title{
        text-align:center; 
        font-weight:700; 
        font-size:21px; 
        color:#4C4C4C
    }
    .create-project-modal-closeBtn{
        font-size:16px; 
        margin-bottom:15px; 
        cursor:pointer
    }
    .create-project-modal-actionBtn{
        font-weight: 700 !important; 
        font-size:12px
    }

    .create-project-modal-actionContainer{
        display:flex;
        align-items:center;
        justify-content:space-between
    }

    .disable{
      opacity:0.7;
      cursor:not-allowed
    }

    button{
      outline: none;
    }

    .emoji-menu{
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 12px;
      gap: 4px;

      width: 68px;
      height: 50px;
      outline: none !important;
      /* Neutrals/200 */
      background: #EDEDED;
      border-radius: 48px 0px 0px 48px;
    }

    .emojis-list-container{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex-flow: wrap;
      justify-content: space-between;
      padding: 7px;

      width: 212px;
      height: 136px;

      background: #FFFFFF;
      /* Shadow- L1 */

      box-shadow: 0px 0px 8px rgba(102, 102, 102, 0.15);
      border-radius: 12px;
    }

    .emojis-list-container span{
      font-size: 20px;
      width: 36px;
      height: 36px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .emojis-list-container span:hover{
      background: #F8F8F8;
      border-radius: 8px;
    }

    .project-name-menu{
      display: flex;
      align-items: center;
      margin-bottom: 30px;
    }
</style>