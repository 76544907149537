import {
    auth,
    usersCollection,
    phUsersCollection
} from "@/firebase";
import axios from 'axios'
import store from '../../store'

const deleteBotRecordingDoc=async(bot_id)=>{
    const URL="https://us-central1-poc-project-309214.cloudfunctions.net/recall_calendar/delete-bot-record";
    const postParams={
        "user_id":auth.currentUser.uid,
        "bot_id":bot_id
    }
    try{
        const response=await axios.post(URL,postParams);
        console.log(response);
        return { status: 'success', data: response}
    }catch(error){
        return { status: 'error', data: error }
    }
}

const getUserBotRecorderName=async()=>{
    try{
        const user=await usersCollection.doc(auth.currentUser.uid).get();
        const userData=await user.data()
        console.log({user:userData});
        return{ status: 'success',userData:userData}
    }catch(error){
        return {status:'failure',erorr:error};
    }
}

const updateUserBotRecorderName=async(recorderName)=>{
    try{
        const user=await usersCollection.doc(auth.currentUser.uid)
        const newBotRecorderName=await user.update({'recorder_name':recorderName})
        return { status: 'success',newBotRecorderName:newBotRecorderName}
    }catch(error){  
        return {status:'failure',erorr:error};
    }
}

const getUserName = async(userId)=>{
    try{
        const user = await usersCollection.doc(userId).get();
        const userData = await user.data()
        return { status: 'success', name : userData.name}
    }catch(error){
        console.log({error})
        return{ status:'failure',erorr:error}
    }
}

const getUserEmailVerified = async()=>{
    const userId = auth.currentUser.uid
    try{
        const user = await usersCollection.doc(userId).get();
        const userData = await user.data()
        console.log('userData: ',userData)
        return { status: 'success', emailVerified : userData.emailVerified}
    }catch(error){
        console.log({error})
        return{ status:'failure',erorr:error}
    }
}

const getPHUser = async(userId)=>{
    try{
        const ph_user = await phUsersCollection.doc(userId).get();
        const userData = await ph_user.data()
        return { status: 'success', data : userData}
    }catch(error){
        console.log('Error:',{error})
        return{ status:'failure',erorr:error}
    }
}

const verifyUserEmail=async()=>{
    try{
        const userDoc=await usersCollection.doc(auth.currentUser.uid);
        const user = await userDoc.get()
        const userData = await user.data()
        if(userData.emailVerified && userData.emailVerified==true){
            return{
                status: 'success', message: 'Email already verified'
            }
        }else{
            const emailVerified =await userDoc.update({
                'emailVerified':true
            })
            return{
                status: 'success', message: 'Email verified', data: true
            }
        }

    }catch(error){
        console.log('Error:',{error})
        store.dispatch("sendAlert", {workflow:'onboarding', error: error})
        return{ status:'failure',erorr:error}
    }
}

const getNudgeCount = async(countType)=>{
    try{
        const nudgeCount = await axios.post('https://us-central1-poc-project-309214.cloudfunctions.net/get_nudges_counts',{
            'user_id' : auth.currentUser.uid,
            'count_type' : countType
        })
        console.log('nudge count',{nudgeCount})
        return {
            status: 'success', ...nudgeCount.data
        }
    }catch(error){
        console.log('Error:',{error})
        return{ status:'failure',erorr:error}
    }
}

const getUserEmailFromReferrerId = async(referrerId) =>{
    try{
        let user_email = null
        const userDocs=await usersCollection.where('referral_id',"==",referrerId).get()
        for (const userDoc of await userDocs.docs){
            const user_data = userDoc.data()
            user_email = user_data['email']
        }
        return { status: 'success', referrer_email: user_email}
    }catch(error){
        console.log('Error:',{error})
        return{status:'failure',erorr:error}
    }
}

export default{
    getUserBotRecorderName,
    updateUserBotRecorderName,
    deleteBotRecordingDoc,
    getUserName,
    getPHUser,
    getNudgeCount,
    verifyUserEmail,
    getUserEmailVerified,
    getUserEmailFromReferrerId
}