import {
    auth,
    transcriptsCollection
  } from "@/firebase";
import axios from "axios";
import CONSTANTS from '../../constants/miscellaneous.js';

const getAllSessions=()=>{
    return null;
}

const getNextPaginatedSessions=async(projectId,lastDocSnapshot)=>{

    try{
        const sessionsData=await transcriptsCollection
        .where("userId", "==", auth.currentUser.uid)
        .where("projectId", "==",projectId)
        .orderBy("createdOn", "desc")
        .startAfter(lastDocSnapshot)
        .limit(8)
        .get()
    
        console.log(sessionsData)
        let sessions=[];
        let lastSessionDoc=null;
        let firstSessionDoc=null;

        for(const session of await sessionsData.docs){
        // console.log(project.id,'-->',project.data().name)
            sessions.push({
            id:session.id,
            ...session.data()
            })
            lastSessionDoc=session;
        }
        firstSessionDoc=await sessionsData.docs[0];

        return { status:'success',sessions: sessions, lastSessionDoc: lastSessionDoc, firstSessionDoc:firstSessionDoc }
    }catch(error){
        return { error, status:'failure', sessions:null, lastSessionDoc:null,firstSessionDoc:null}
    }

}

const getPreviousPaginatedSessions=async(projectId,firstDocSnapshot)=>{
    const sessionsData=await transcriptsCollection
                                .where("userId", "==", auth.currentUser.uid)
                                .where("projectId", "==",projectId)
                                .orderBy("createdOn", "desc")
                                .limitToLast(8)
                                .endBefore(firstDocSnapshot)
                                .get()

    let sessions=[];
    let lastSessionDoc=null;
    let firstSessionDoc=null;

    for(const session of await sessionsData.docs){
        // console.log(project.id,'-->',project.data().name)
        sessions.push({
        id:session.id,
        ...session.data()
        })
        lastSessionDoc=session;
    }
    firstSessionDoc=await sessionsData.docs[0];
    return { sessions: sessions, lastSessionDoc: lastSessionDoc, firstSessionDoc: firstSessionDoc}

}

const getProjectSessions=async(projectId)=>{
    const sessionsData=await transcriptsCollection
                            .where("userId", "==", auth.currentUser.uid)
                            .where("projectId", "==",projectId)
                            .orderBy("createdOn", "desc")
                            .limit(8)
                            .get()

      let sessions=[];
      let lastSessionDoc=null;
      let firstSessionDoc=null;
      for(const session of await sessionsData.docs){
        // console.log(project.id,'-->',project.data().name)
        sessions.push({
            id:session.id,
            ...session.data()
        })
        lastSessionDoc=session;
    }
    firstSessionDoc=await sessionsData.docs[0];
    console.log({sessions})
    return { sessions: sessions, lastSessionDoc: lastSessionDoc, firstSessionDoc: firstSessionDoc}

}

const getAllSessionsForProject=async(projectId)=>{
    const sessionsData=await transcriptsCollection
                            // .where("userId", "==", auth.currentUser.uid)
                            .where("projectId", "==",projectId)
                            .orderBy("createdOn", "desc")
                            .get()

      let sessions=[];
      for(const session of await sessionsData.docs){
        // console.log(project.id,'-->',project.data().name)
        const sessionData=session.data()
        let _session = {
            id:session.id,
            projectId:sessionData.projectId,
            fileName:sessionData.fileName,
        }
        if('moveCallInProgress' in sessionData) _session = { ..._session, moveCallInProgress: sessionData.moveCallInProgress}
        sessions.push(_session)
    }
    return sessions;
}

const updateSessionName=async(sessionId,newSessionName)=>{
    try{
        const sessionToUpdate=await transcriptsCollection.doc(sessionId);
        const updatedSession=await sessionToUpdate.update({fileName:newSessionName,updatedOn:new Date()})
        return { status:'success' }
    }catch(error){
        return { status:'error',error:error }
    }
   
}

const toggleSessionShareable=async(sessionId,viewState)=>{

    try{
        const sessionToUpdate=await transcriptsCollection.doc(sessionId);
        const updatedSession=await sessionToUpdate.update({
                                        shareable: viewState,
                                        updatedOn: new Date(),
                                    });
        return { status:'success' }  
    }catch(error){
        return { status:'error',error:error }
    }
    
}

const getAllSessionsForUser=async()=>{
    try{
        const sessionsData=await transcriptsCollection.where("userId","==",auth.currentUser.uid)
                             .get();
        let sessions=[];
        for(const session of await sessionsData.docs){
            sessions.push({
                id:session.id,
                projectId:session.data().projectId,
                fileName:session.data().fileName,
            })
        }
        return {sessions:sessions,status:'success'};

    }catch(error){
        return{ status:'error',error:error}
    }
}

const getSessionDetailsByTranscriptId=async(transcriptId)=>{
    try{
        const sessionRef=await transcriptsCollection.doc(transcriptId)
        const sessionData = await sessionRef.get()
        if(sessionData.exists){
            return {
                session:{
                    id: transcriptId,
                ...sessionData.data()
                },
                status: 'success'
            }
        }else{
            console.error('session not found',transcriptId)
            return null
        }

    }catch(error){
        return{
            status:'error',error:error
        }
    }
}

const getSessionDetailsByFilename=async(fileName)=>{
    try{
        const sessionsData=await transcriptsCollection
                            .where("userId", "==", auth.currentUser.uid)
                            .where("fileName", "==",fileName)
                            .get()
        let sessions=[];
        for(const session of await sessionsData.docs){
            // console.log(project.id,'-->',project.data().name)
            sessions.push({
                id:session.id,
                ...session.data()
            })
        }
        return {session:sessions[0],status:'success'};

    }catch(error){
        return{
            status:'error',error:error
        }
    }
}

const moveNotesToAnotherSectionInASession=async(noteIds,moveToQuestionId,moveToQuestionName)=>{

    const requestUrl="https://us-central1-poc-project-309214.cloudfunctions.net/move_notes_sections";
    const requestParams= {
        "user_id":auth.currentUser.uid,
        "note_ids": noteIds,
        "move_to_question_id":moveToQuestionId,
        "move_to_question_name":moveToQuestionName
    }
       
    console.log({requestParams:requestParams})
    try{
        const response=await axios.post(requestUrl,requestParams);
        console.log('response',response)
        return {
            status:'success', response: response
        }
    }catch(error){
        return {
            status:'error', error:error
        }
    }
}

const getAllBotRecordedSessions=async()=>{
    try{
        const sessionsData = await transcriptsCollection
                                    .where("userId", "==", auth.currentUser.uid)
                                    .orderBy("recordingStatus")
                                    .get();
        let sessions=[];
        for(const session of await sessionsData.docs){
            // console.log(project.id,'-->',project.data().name)
            sessions.push({
                id:session.id,
                ...session.data()
            })
        }
        return {sessions:sessions,status:'success'};
        
    }catch(error){
        console.log(error);
        return {status:'error',error:error};
    }
}


const deleteAllAINotes=async(call_id)=>{
    // const requestUrl="http://127.0.0.1:8081";
    const requestUrl="https://us-central1-poc-project-309214.cloudfunctions.net/manage_auto_generated_notes";
    let _requestParams = {}
    _requestParams= {
        "user_id":auth.currentUser.uid,
        "call_id": call_id,
        "action":"DELETE_ALL"
    }
    try{
        const response=await axios.post(requestUrl,_requestParams);
        // console.log('response',response)
        return {
            status:'success', response: response
        }
    }catch(error){
        return {
            status:'error', error:error
        }
    }
}

const saveModifiedTranscipt=async(_requestParams)=>{
    // const requestUrl="http://127.0.0.1:8081";
    const requestUrl="https://us-central1-poc-project-309214.cloudfunctions.net/update_transcript";
    try{
        const response=await axios.post(requestUrl,_requestParams);
        // console.log('response',response)
        return {
            status:'success', response: response
        }
    }catch(error){
        return {
            status:'error', error:error
        }
    }
}

const createNewTextFile=async(projectId,rawTextHTML,rawTextString,rawTextJSON,fileName)=>{
    // const requestUrl="http://192.168.1.2:8081";
    const requestUrl="https://us-central1-poc-project-309214.cloudfunctions.net/import_raw_text";
    const requestBody={
        'project_id': projectId,
        'raw_text_html': rawTextHTML,
        'raw_text_string': rawTextString,
        'raw_text_json': rawTextJSON,
        'user_id': auth.currentUser.uid,
    }
    if(fileName){
        requestBody['fileName'] = fileName
    }
    try{
        const response=await axios.post(requestUrl,requestBody);
        // console.log('response',response)
        return {
            status:'success', response: response
        }
    }catch(error){
        return {
            status:'error', error:error
        }
    }
}

const uploadTranscript=async(projectId,rawTranscriptHTML,rawTranscriptString,rawTranscriptJSON,fileName)=>{
    // const requestUrl="http://192.168.1.9:8080";
    const requestUrl="https://us-central1-poc-project-309214.cloudfunctions.net/upload_transcript";
    const requestBody={
        'project_id': projectId,
        'raw_transcript_html': rawTranscriptHTML,
        'raw_transcript_string': rawTranscriptString,
        'raw_transcript_json':rawTranscriptJSON,
        'user_id': auth.currentUser.uid,
    }
    if(fileName){
        requestBody['fileName'] = fileName
    }
    try{
        const response=await axios.post(requestUrl,requestBody);
        // console.log('response',response)
        return {
            status:'success', response: response
        }
    }catch(error){
        return {
            status:'error', error:error
        }
    }
}

export default {
    getProjectSessions,
    updateSessionName,
    toggleSessionShareable,
    getNextPaginatedSessions,
    getPreviousPaginatedSessions,
    getSessionDetailsByFilename,
    getSessionDetailsByTranscriptId,
    getAllSessionsForUser,
    getAllBotRecordedSessions,
    getAllSessionsForProject,
    moveNotesToAnotherSectionInASession,
    deleteAllAINotes,
    saveModifiedTranscipt,
    createNewTextFile,
    uploadTranscript
}