<template>
    <div>
        <v-dialog
            width="1100"
            v-model="showUpgradePlanModal"
            content-class="upgrade-plan-modal"
            @click:outside="closeUpgradePlanModal"
        >
        <v-card class="upgrade-plan-modal__container" style="border-radius:16px">
            <v-card-title class="upgrade-plan-title-container">
                <h3 class="looppanel-typography-normal" v-if="!trialEnded && !subscriptionActive">You have {{trialDaysLeft}} days of Free Trial remaining</h3>
                <h3 class="looppanel-typography-normal" v-else-if="trialEnded && !subscriptionActive && planEndDate == null">Your Free Trial has ended</h3>
                <h3 class="looppanel-typography-normal" v-else-if="trialEnded && !subscriptionActive && planEndDate != null">Your subscription has expired</h3>
                <h3 class="looppanel-typography-normal" v-else-if="noTranscriptionDuration">You’re out of transcription hours for this month</h3>
                <h3 class="looppanel-typography-normal" v-else-if="activePlan=='Solo'">Upgrade to invite members to your workspace</h3>
                <h3 class="looppanel-typography-normal" v-else>You have <span v-if="transcriptionDuration.hours" class="looppanel-typography-normal">{{transcriptionDuration.hours}} hours</span> <span v-if="transcriptionDuration.minutes" class="looppanel-typography-normal">{{transcriptionDuration.minutes}} mins</span> of transcription left this month</h3>
                <i class="ri-close-fill" @click="closeUpgradePlanModal"></i>
            </v-card-title>
            <div class="upgrade-plan-content-container">
                <h1 class="modal-text looppanel-typography-medium" v-if="!trialEnded && !subscriptionActive">Your paid plan will begin after your Free Trial ends</h1>
                <h1 class="modal-text looppanel-typography-medium" v-else-if="trialEnded && !subscriptionActive">You now have limited view access to your workspace. Please upgrade to a paid plan to make the most out of Looppanel.</h1>
                <h1 class="modal-text looppanel-typography-medium" v-else-if="activePlan=='Solo'">Upgrade to the Teams Plan to create a team workspace to collaborate with members.</h1>
                <h1 class="modal-text looppanel-typography-medium" v-else>Your transcription limit will next reset on {{planEndDate}}. Upgrade to increase your transcription limit and unlock more features now.</h1>
                {{formattedPlanEndDate}}

                <div class="plan-switch-container">
                    <h1 :style="selectedPlanDuration=='monthly'? 'color: #4C4C4C; font-weight: 600; margin-left:100px': 'margin-left:100px'">Monthly</h1>
                    <v-switch
                        @change="togglePlanDuration"
                        :input-value="true"
                        :color='"#33B273"'
                        inset
                        style="margin: 15px 0px 0px 15px; padding-top: 6px"
                    /> 
                    <span class="annual-plan-container">
                    <h1 :style="selectedPlanDuration=='annual'? 'color: #4C4C4C; font-weight: 600': ''">Annual</h1>
                    <span>Save 10%</span>
                    </span>
                </div>
                <div class="plan-card-container">
                <template v-for="(plan, index) in plans.filter((plan)=>(plan.duration == selectedPlanDuration || plan.duration == ''))">
                    <PlanCard :key="index" :plan={...plan} v-on:open-checkout-cancelled-snackbar="openCheckoutCancelledSnackbar" v-on:open-checkout-success-snackbar="openCheckoutSuccessSnackbar"  ></PlanCard>
                </template>
                </div>
            </div>
            <v-card-actions class="upgrade-plan-actions-container">
                <a href="https://looppanel.com/pricing" class="compare-plans-cta" target="_blank">Compare Plans <i class="ri-external-link-fill" style="margin-left:5px; font-size: 14px"></i> </a>
            </v-card-actions>
        </v-card>
        </v-dialog>

        <Snackbar  
            :timeout="5000"
            :rounded="'pill'"
            :snackbarColor="'#33B273'"    
            message="Looppanel purchase was successful, this may take a minute to reflect on your account"
            :showSnackbar="showCheckoutSuccessSnackbar"
            v-on:close-notification="showCheckoutSuccessSnackbar = false"
        />
        <Snackbar  
            :rounded="'pill'"
            :snackbarColor="'#C53030'"    
            message="Looppanel purchase was cancelled"
            :showSnackbar="showCheckoutCancelledSnackbar"
            v-on:close-notification="showCheckoutCancelledSnackbar = false"
        />
    </div>
</template>

<script>
import PlanCard from '../cards/PlanCard.vue';
import Snackbar from '../notifications/Snackbar.vue';
import format from "date-fns/format"; 


const Paddle = window.Paddle;
export default{
    props: {
        // showUpgradePlanModal: {}
    },
    computed:{
        activePlan(){
            return this.$store.state.billingDetails.plan;
        },
        showUpgradePlanModal(){
            return this.$store.state.showUpgradePlanModal;
        },
        trialDaysLeft(){
            return this.$store.state.billingDetails.trialDaysLeft;
        },
        trialEnded(){
            return this.$store.state.billingDetails.trialEnded;
        },
        subscriptionActive(){
            return this.$store.state.billingDetails.subscriptionActive;
        },
        transcriptionDuration(){
            return this.$store.state.remainingTranscriptionDuration;
        },
        planEndDate(){
            let planEndDate = this.$store.state.billingDetails.planEndDate;
            if(planEndDate == null) return null;
            planEndDate = format(new Date(planEndDate), "do MMMM, yyyy")
            return planEndDate
        },
        noTranscriptionDuration(){
            let transcriptionDuration = this.$store.state.remainingTranscriptionDuration
            return transcriptionDuration.hours == 0 && transcriptionDuration.minutes < 10 
        },
    },
    data(){
        return {
            showCheckoutSuccessSnackbar: false,
            showCheckoutCancelledSnackbar: false,
            selectedPlanDuration: 'annual',
            plans: [
                {
                    planName: 'solo',
                    planId: '841309',
                    // planId: '65374',
                    planDescription: 'for solo researchers',
                    price: '$30',
                    duration: 'monthly',
                    features: 'Includes 5 transcription hours/month, AI notes, view-only collaborators & analysis capabilities.'
                },
                {
                    planName: 'teams',
                    planId: '854863',
                    // planId: '65374',
                    planDescription: 'for research teams',
                    price: '$350',
                    duration: 'monthly',
                    features: 'Includes everything in solo, 30 transcription hours/month, teams workspace & unlimited collaborators.'
                },
                {
                    planName: 'business',
                    planId: '',
                    planDescription: 'for larger orgs',
                    price: '$1,000',
                    duration: 'monthly',
                    features: 'Includes everything in teams, 120 transcription hours/month, SSO & Priority support'
                },
                {
                    planName: 'solo',
                    planId: '842050',
                    // planId: '65380',
                    planDescription: 'for solo researchers',
                    price: '$324',
                    duration: 'annual',
                    features: 'Includes 60 transcription hours/year, AI notes, view-only collaborators & analysis capabilities'
                },
                {
                    planName: 'teams',
                    planId: '854864',
                    // planId: '65425',
                    planDescription: 'for research teams',
                    price: '$3,900',
                    duration: 'annual',
                    features: 'Includes everything in solo, 360 transcription hours/year, teams workspace & unlimited collaborators.'
                },
                {
                    planName: 'business',
                    planId: '',
                    planDescription: 'for larger orgs',
                    price: '$12,000',
                    duration: 'annual',
                    features: 'Includes everything in teams, 120 transcription hours/month, SSO & Priority support'
                },
                {
                    planName: 'enterprise',
                    planId: '',
                    planDescription: 'for enterprises',
                    price: 'Custom',
                    duration: '',
                    features: 'Includes everything in business, dedicated support and custom access control'
                }
            ],
        };
    },
    components: {
        PlanCard, Snackbar
    },
    methods: {

        openCheckoutCancelledSnackbar(){
            this.showCheckoutCancelledSnackbar = false
            this.showCheckoutCancelledSnackbar = true
            setTimeout(()=>{
                this.showCheckoutCancelledSnackbar = false
            },1500)
        },
        openCheckoutSuccessSnackbar(){
            this.showCheckoutSuccessSnackbar = false
            this.showCheckoutSuccessSnackbar = true
            setTimeout(()=>{
                this.showCheckoutSuccessSnackbar = false
            },5000)
        },
        closeUpgradePlanModal(){
            //console.log("close upgrade plan modal")
            this.$store.dispatch('toggleUpgradePlanModal',false)
            this.selectedPlanDuration = "monthly"
        },
        togglePlanDuration(){
            //console.log("old plan duration", this.selectedPlanDuration)
            if(this.selectedPlanDuration == "monthly"){
                this.selectedPlanDuration = "annual"
            } else this.selectedPlanDuration = "monthly"
        },
    },
    mounted() {
        this.showCheckoutSuccessSnackbar = false;
        this.showCheckoutCancelledSnackbar = false;
    }
}
</script>

<style scoped>
    .upgrade-plan-modal,.upgrade-plan-modal__container{
        border-radius: 16px !important;
        margin: 0px;
      }

    .upgrade-plan-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 24px 28px;
    }

    .upgrade-plan-content-container .modal-text {
        text-align: left;
        color: var(--grey-font-color);
        width: 100%;
    }
    .upgrade-plan-title-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #EDEDED;
        height: 62px;

    }

    .upgrade-plan-actions-container{
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 1px solid #EDEDED;
        height: 70px;
    }

    .upgrade-plan-title-container h3,
    .upgrade-plan-title-container span{
        line-height: 25px;
        color: var(--dark-grey-font-color);
    }

    .upgrade-plan-title-container i{
        color:#666666;
        cursor: pointer;
    }

    .plan-switch-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FAFBFE;
        width: calc(100% + 20px);
        height: 40px;
        border: 1.5px solid #D2DCFB;
        border-radius: 16px;
        margin: 15px 0px;
        padding: 20px !important;
    }

    .plan-switch-container h1 {
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 0px;
        text-align: center;
        color: #999999;
        transition: 0s;
    }
    .annual-plan-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .annual-plan-container span {
        background: var(--success-green-color);
        font-family: Inter;
        font-size: 12px;
        font-weight: 500;
        line-height: 10px;
        letter-spacing: 0px;
        text-align: center;
        color: #FFFFFF;
        padding:6px;
        border-radius: 8px;
        margin-left: 5px;
    }

    .compare-plans-cta {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 140px;
        height: 34px;
        padding: 8px, 16px, 8px, 16px !important;
        border-radius: 32px;
        border: 1px solid #5B7EDB;
        color: #5B7EDB;
        background: #FFFFFF;
        font-family: Inter;
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        margin: 16px, 0px 20px, 0px    
    }
    .plan-card-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
