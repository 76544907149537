<template>
    <div class="dropdown-body">
      <v-menu v-model="dropdownState" :disabled="disableDropDown" content-class="default-menu-config" offset-y :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <label class="dropdown-label">{{ label }}</label>
        <button
          class="selected-option"
          v-bind="attrs"
          v-on="on"
          @click="dropdownOpened = !dropdownOpened"
        >
          {{ selectedValue }} 
          <span v-if="selectedValue.length == 0"> Select a value</span>
          <span class="ml-auto">
            <i v-if="!dropdownOpened"  class="ri-arrow-down-s-line dropdown-icon"></i>
            <i v-else  class="ri-arrow-up-s-line dropdown-icon"></i>
          </span>
        </button>
      </template>
      <div class="drodown-background">
        <div v-if="enableAddOption" class="basic-dropdown-add-input-option-container">
          <input class="basic-dropdown-add-input-option" @keypress.enter="handleAddOption(id)" v-model="addNewOptionValue" placeholder="Type and enter to add" />
        
          <button
            class="basic-dropdown-save-cta"
            v-if="addNewOptionValue.length != 0"
            @click="handleAddOption(id)"
          >
            <p>+ Create Value '{{addNewOptionValue}}'</p>
          </button>
        </div>
        <div
          class="dropdown-list"
          v-for="(item, index) in options"
          :key="index"
        >
          <div v-if="item == selectedValue" class="dropdown-list-option-selected" @click="handleRemoveSelection(item,index)">{{ item }} 
            <span class="ml-auto">
              <i class="ri-check-line dropdown-icon"></i>
            </span>
          
          </div>
          <div v-else class="dropdown-list-option" @click="handleSelection(item,index)" >{{ item }}</div>
        </div>
      </div>
    </v-menu>

    </div>
  </template>
  
  <script>
  export default {
    props: {
      label: String,
      id: String,
      value: String,
      options: Array, // Define the options prop
      enableAddOption: {
        type:Boolean,
        required: false,
      },
      disableDropDown:{
        type:Boolean,
        required: false,
      }
    },
    data() {
      return {
        dropdownState:null,
        selectedValue: this.value || '', // Default selected value
        dropdownOpened:false,
        addNewOptionValue:''
      };
    },
    watch: {
      value(newValue) {
        this.selectedValue = newValue; // Update selectedValue when the value prop changes
      },
    },
    methods:{
      handleAddOption(id){
        if (id == 'new_option'){
          this.dropdownState = false;
          this.dropdownOpened = false;
          this.selectedValue = this.addNewOptionValue;
          this.$emit('dropdown-add-option', this.addNewOptionValue,id);
          this.addNewOptionValue = ''
        }
        else {
          this.dropdownOpened = false;
          this.$emit('dropdown-add-option', this.addNewOptionValue,id);
          this.addNewOptionValue = ''
        }
      },
      // Handles selected option
      handleSelection(item,index){
        // console.log('item',item,index)
        this.selectedValue=item
        this.dropdownOpened = false;
        this.dropdownState = false;
        this.$emit('dropdown-selection', item);
      },
      handleRemoveSelection(item,index){
        // console.log('item',item,index)
        this.selectedValue=''
        this.$emit('dropdown-remove-selection', item);
      }
    }
  };
  </script>
  
  <style scoped>
  .basic-dropdown-add-input-option-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 8px;
  }
  .basic-dropdown-save-cta{
    display: flex;
    padding: 4px 8px;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    background: var(--Neutrals-White, #FFF);
    color: var(--Neutrals-400, #4C4C4C);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin-left:12px;
    margin-right:12px;
    height:32px;
    padding-top:16px;
  }
  .basic-dropdown-save-cta:hover{
    background: var(--Neutrals-White, #f8f8f8);
  }
  .basic-dropdown-add-input-option{
    display: flex;
    padding: 4px 8px;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    border: 1.5px solid var(--Primary-500, #EDEDED);
    background: var(--Neutrals-White, #FFF);
    color: var(--Neutrals-400, #4C4C4C);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin:12px;
  }
  .basic-dropdown-add-input-option:focus{
    border: 1.5px solid var(--Primary-500, #5B7EDB);
  }
  .basic-dropdown-add-input-option::placeholder{
    color: var(--Neutrals-400, #999);
  }
  .default-menu-config{
    box-shadow: none;
    background: #FFFFFF;
    border-radius: 8px;
    padding:0px !important;
    margin-top: 4px;
    border: 1px solid #D2DCFB;
  }
  .dropdown-label{
    color: #999;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin-right:6px;
  }
  .dropdown-body{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .selected-option{
    color: #4C4C4C;
    display: flex;
    min-width: 120px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: white;
    height: 100%;
    outline: none;
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
    padding: 4px 4px 4px 8px;
    gap: 4px;
    border-radius: 8px;
    border: 1px solid  #D2DCFB;
    background: #FFF;
  }
  .drodown-background{
    box-shadow: none;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(102, 102, 102, 0.05);
    border-radius: 8px;
    padding:0px !important;
  }
  .dropdown-list{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #FFF;
    gap: 4px;
    margin-left: 12px;
    margin-right: 12px;
    margin-top:6px;
    margin-bottom: 6px;
  }
  .dropdown-list-option{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height, or 18px */
    display: flex;
    align-items: left;
    justify-content: left;
    cursor:pointer;
    /* Neutrals/500 */
    color: #4C4C4C;
    padding: 6px 8px 4px 8px;
    width: 100%;
    border-radius: 4px;
  }

  .dropdown-list-option-selected{
    background: #F6F8FE;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height, or 18px */
    display: flex;
    align-items: left;
    justify-content: left;
    cursor:pointer;
    /* Neutrals/500 */
    color: #4C4C4C;
    padding: 6px 8px 4px 8px;
    width: 100%;
    border-radius: 4px;
  }
  .dropdown-list-option:hover{
    background: #FAFBFE;
  }
  .dropdown-icon{
    width: 20px;
    height: 20px;
    color: #5B7EDB;
  }
  </style>
  