const calendar={
    dummy_auth_token:'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjhmZGVmMzdkLWQ4OGMtNDk0ZS05Nzc3LWI2ZGQxZGM1ZjM5ZCIsImV4cGlyZXNfYXQiOiIyMDIyLTA2LTEwVDEzOjIzOjU5Ljg4MzE1OSJ9.KsSKov6lapRD7qkw6rGvabeMzOUU6yFXJZNKH_Mbvkg',
    // clientId:'713264274646-t6ifofh40otmot798e25sp0a1qhq914n.apps.googleusercontent.com',
    clientId:'123938885377-3eo2j3r5ckho8b146nvpk9kq3errehli.apps.googleusercontent.com',
    // redirect_uri:'https://api.recall.ai/api/v1/calendar/google_oauth_callback/',
    redirect_uri:'https://app.looppanel.com/api/oauth/google-oauth-callback',
    GOOGLE_OAUTH_PERMISSION_SCOPES : [
        "https://www.googleapis.com/auth/calendar.events.readonly",
        "https://www.googleapis.com/auth/calendar.readonly",
        "https://www.googleapis.com/auth/userinfo.email",
        "https://www.googleapis.com/auth/userinfo.profile",
    ],
    Calendar_API_Host:'https://api.recall.ai',
    API_GetCalendarToken:"https://us-central1-poc-project-309214.cloudfunctions.net/get_calendar_token",
    API_GetCalendarUser:"https://api.recall.ai/api/v1/calendar/user/",
    API_GetCalendarMeetings:"https://api.recall.ai/api/v1/calendar/meetings/",
    API_UpdateCalendarMeeting:"https://api.recall.ai/api/v1/calendar/meetings/",
    API_RefreshCalendarMeetings:"https://api.recall.ai/api/v1/calendar/meetings/refresh/",
    API_RefreshCalendarMeetingsLoop:"https://us-central1-poc-project-309214.cloudfunctions.net/recall_calendar/refresh-list-meetings",
    API_RetrieveMeetingInformationLoop:"https://us-central1-poc-project-309214.cloudfunctions.net/recall_calendar/get-calendar-meeting",
    API_UpdateRecordingPreferences:"https://api.recall.ai/api/v1/calendar/user/",
    months:['January','February','March','April','May','June','July','August','September','October','November','December'],
    API_GetCalendarMeetingsLoop:"https://us-central1-poc-project-309214.cloudfunctions.net/recall_calendar/list-meetings",

}

export default calendar;