<template>
  <div v-if="!isSessionShare"  :style="{'marginBottom' : showingBanner ? '50px' : (editorNav || settingsNav) ? '0px' : '20px'}">
    <div>
      <MigratingWorkspaceLoaderModal 
        :showMigratingWorkspaceLoader="migratingWorkspace" 
      />
    </div>
    <!-- showing banner : {{  showingBanner }} -->
    <template v-if="editorNav || searchNav">
    </template>
    <template v-else-if="notetakingNav || showMinimalNavbar">
      <div class="notetaking-nav">
          <span v-if="notetakingNav" class="nav-back-button" @click="goBack">
              <i class="ri-arrow-left-line"></i>
          </span>
          <div>
            <v-img width="36px" max-width="36px" margin="0px 0px 0px 0px" src="../../../public/loop-loader.svg"></v-img>
            <p>Looppanel</p>
          </div> 
      </div>
    </template>
    <!-- Render the Export Excel and Insights CTA component here -->
    <template v-else-if="analysisNav">

    </template>
   
    <template v-else-if="brewNoteNav">
    </template>

    <template v-else>
      <v-card
        style="border-bottom: 1px solid #EDEDED;"
        flat
        tile
        class="nav-container"
      >
        <!-- Show chrome Trial end annoucement bar only on home page -->
        <AnnouncementBar v-if="trialEnded && !subscriptionActive" :color="'#C53030'" >
          <h1 v-if="planEndDate == null">Your Looppanel trial has ended. You now have limited access to your workspace. Please upgrade to make the most out of Looppanel.</h1>
          <h1 v-else>Your Looppanel subscription has ended. You now have limited access to your workspace. Please upgrade to make the most out of Looppanel.</h1>
          <a @click="handleAnnoucementLinkClick('upgrade-plan')" >
            Upgrade
          </a>
          <i class="ri-arrow-right-line" style="fontSize:14px; marginLeft:-5px"></i>
        </AnnouncementBar>

        <!-- Show chrome extension annoucement bar only on home page -->
        <AnnouncementBar v-else-if="this.$route.name==='Dashboard' && !trialEnded" >
          <h1>Make the most out of your trial. Create a project, add calls and get high quality transcripts and AI notes ✨</h1>
          <a @click="handleAnnoucementLinkClick('open-project-modal')" >
          Create a project
          </a>
          <i class="ri-arrow-right-line" style="fontSize:14px; marginLeft:-5px"></i>
        </AnnouncementBar>
  
         <!-- Show chrome extension annoucement bar only on home page -->
         <AnnouncementBar v-else-if="this.$route.name==='Dashboard' && showEmailVerificationBanner" >
          <h1>Please verify your email to keep your account secure. We’ve sent a verification link to <b>{{currentUser.email}}</b></h1>
          <a @click="handleAnnoucementLinkClick('verify-email')" >
            Resend link
          </a>
          <i class="ri-arrow-right-line" style="fontSize:14px; marginLeft:-5px"></i>
        </AnnouncementBar>

        <div class="nv-bar">
          <div class="nv-left">
            <!-- <router-link to="/" class="looppanel-logo" style="margin-right:25px; margin-left:16px">
                <v-img width="37px" max-width="36px" margin="0px 0px 0px 0px" src="../../../public/loop-loader.svg"></v-img>
            </router-link> -->
            <WorkspaceMenu/>
            <span v-if="!globalNav" class="nav-back-button" @click="goBack">
              <i class="ri-arrow-left-line"></i>
            </span>
            <template v-if="globalNav">
                    <Link :showSearch="searchEnabled" :globalNav="globalNav" :key="link" :link="link" v-for="link in mainlinks" />
            </template>
                    <template v-else>
                      <div v-if="project" style="display:flex; justify-content:center; align-items:center">
                        <!-- {{project}} -->
                        <ChangeCallMenu :disableMenu="isCollaborator" :calls="allProjects" type="project">
                          
                          <template v-slot:custom-activator>
                            <span class="project-name-dropdown">
                              <template v-if="project.default">
                                    <img class="mr-3" src="../../../public/loop-logo-small.svg" />
                              </template>
                              <template v-else>
                                {{project.emoji}}
                              </template>
                              <!-- {{project.name.slice(0, 40)}} -->
                              <!-- {{project.name}} -->
                             {{(project.name.length > 40) ? project.name.slice(0, 36) + ' ...' : project.name}}
                             <template v-if="isCollaborator">
                                <div class="view-only-label" v-if="showReadOnlyLabel">
                                  View-only
                                </div>
                             </template>
                             <template v-else>
                                <i v-if="!isCollaborator" style="color:#999999; margin-left:5px" class="ri-arrow-down-s-line"></i>
                             </template>
                            </span>
                            
                          </template>
                        </ChangeCallMenu> 
                        <!-- <ManageProjectMetadata style="margin-left:10px"/> -->
                      </div>
                </template>
          </div>

          <div class="nv-center">
            <template v-if="!globalNav">
                    <template v-if="isDefaultProject">
                      <Link
                         :key="link" :link="link" v-for="link in defaultProjectLinks" 
                        :globalNav="false" :relativePath="`/project/${projectId}`"
                      />
                    </template>
                    <template v-else>
                      <template v-if="hideLinks">
                      </template>
                      <template v-else>
                        <Link
                         v-on:track-calls-nudge="trackCallsNudge"
                        :showBeacon="showScriptNudge"
                         :key="link" :link="link" v-for="link in projectlinks" 
                        :globalNav="false" :relativePath="`/project/${projectId}`"
                      />
                      </template>
                     
                    </template>

                  </template>
          </div>

          <div class="nv-right">
            <template v-if="globalNav">
                    <HelpButton/>
                    <StartRecording/>
            </template>
            <template v-else>
              <template v-if="isCollaborator">
                <div class="avatar-container" v-if="ownerName">
                  <!-- <avatar :size="30" :username="ownerName"></avatar> -->
                  <v-tooltip content-class="tooltip-collaborator" bottom>
                          <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on" class="mr-1">
                                  <avatar :size="30" :username="ownerName" ></avatar>
                              </div>
                          </template>
                          <span>{{ ownerName }}</span>
                  </v-tooltip>
                </div>
              </template>
              <template v-else>
                <div class="invita-collab-btn-container">
                  <HelpButton :style="{marginLeft:(project && project.name!=='Unassigned Calls')?'':'200px'}"/>
                  <template v-if="showInviteCollaboratorButton">
                    <UpgradePlanTooltip 
                      :showTooltip="trialEnded && !subscriptionActive" 
                      :message="'Your Free Trial has ended. Please upgrade to invite collaborators'"
                      renderedIn="Share Project on Projects view"
                    >
                      <InviteCollaborator
                        v-if="!isCollaborator" 
                        :disabled="trialEnded && !subscriptionActive"
                        :projectId="projectId" 
                        :project="project" 
                        ref="inviteCollaboratorRef"
                      />
                      <LoopColoredAvatar 
                          class="mt-1"
                          v-if="isWorkspaceMember"
                          :showTooltip="true"
                          :tooltipText="project.createdBy ? project.createdBy : ''"
                          :avatarText="project.createdBy ? project.createdBy : ''"
                          size="medium"
                      />
                    </UpgradePlanTooltip>
                  </template>
                </div>
              </template>
            </template>
          </div>

        </div>


        <v-toolbar class="navbar" elevation="0" v-if="false">
            <router-link to="/" class="looppanel-logo" style="margin-right:25px">
                <v-img width="37px" max-width="36px" margin="0px 0px 0px 0px" src="../../../public/loop-loader.svg"></v-img>
            </router-link>
            <span v-if="!globalNav" class="nav-back-button" @click="goBack">
              <i class="ri-arrow-left-line"></i>
            </span>

            <div class="nav-outer" >
                <div class="nav-left">
                    <template v-if="globalNav">
                      <Link :globalNav="globalNav" :key="link" :link="link" v-for="link in mainlinks" />
                    </template>
                    <template v-else>
                      <div v-if="project">
                        <!-- {{project}} -->
                        <ChangeCallMenu :disableMenu="isCollaborator" :calls="allProjects.projects" type="project">
                          <template v-slot:custom-activator>
                            <span class="project-name-dropdown">
                              <template v-if="project.default">
                                    <img class="mr-3" src="../../../public/loop-logo-small.svg" />
                              </template>
                              <template v-else>
                                {{project.emoji}}
                              </template>
                              <!-- {{project.name.slice(0, 40)}} -->
                              <!-- {{project.name}} -->
                             {{(project.name.length > 40) ? project.name.slice(0, 36) + ' ...' : project.name}}
                              <i v-show="!isCollaborator" style="color:#999999; margin-left:5px" class="ri-arrow-down-s-line"></i>
                            </span>
                            
                          </template>
                        </ChangeCallMenu>
                      </div>
                    </template>
                </div>
                <div class="nav-middle">
                  <template v-if="!globalNav">
                    <template v-if="isDefaultProject">
                      <Link
                         :key="link" :link="link" v-for="link in defaultProjectLinks" 
                        :globalNav="false" :relativePath="`/project/${projectId}`"
                      />
                    </template>
                    <template v-else>
                      <template v-if="hideLinks">
                      </template>
                      <template v-else>
                        <Link
                          :showBeacon="showScriptNudge"
                         :key="link" :link="link" v-for="link in projectlinks" 
                        :globalNav="false" :relativePath="`/project/${projectId}`"
                      />
                      </template>
                     
                    </template>

                  </template>
                </div>
                <div class="nav-right">
                  <template v-if="globalNav">
                    <StartRecording/>
                  </template>
                </div>
                
            </div>
        </v-toolbar>
      </v-card>
    </template>
  </div>
</template>

<script>
import ROUTES from '../../constants/routes';
import Link from './Link.vue';
import StartRecording from '../misc/StartRecording.vue';
import ProjectsAPI from '@/api/projects/projects';
import QuestionScriptsAPI from '@/api/question-script/question-scripts';
import ChangeCallMenu from '../menus/ChangeCallMenu.vue';
import AnnouncementBar from '../announcementBar/AnnouncementBar.vue';
import {auth} from '@/firebase'
import InviteCollaborator from '@/views/analysis/InviteCollaborator.vue';
import Avatar from 'vue-avatar';
import UsersAPI from '@/api/user/user';
import WorkspaceAPI from '@/api/workspace/workspace';
import { mapState } from "vuex";
import HelpButton from "../buttons/HelpButton.vue"
import WorkspaceMenu from '../menus/WorkspaceMenu.vue';
import UpgradePlanTooltip from '@/components/UpgradePlanTooltip.vue';
import LoopColoredAvatar from '../avatars/LoopColoredAvatar.vue';
import TransactionalEmailAPI from '@/api/TransactionalEmailAPI';
import MigratingWorkspaceLoaderModal from '@/components/modals/MigratingWorkspaceLoaderModal.vue'
export default {
    data() {
        return {
            showMinimalNavbar: false,
            invitationId: this.$route.query.invitation,
            globalNav:this.$route.params.projectId?false:true,
            notetakingNav:false,
            editorNav:false,
            searchNav:false,
            brewNoteNav: false,
            brewNoteHomeNav: false,
            settingsNav: false,
            mainlinks: ROUTES['mainlinks'],
            projectlinks:ROUTES['projectlinks'],
            navLogo: "loop-logo.svg",
            projectId:this.$route.params.projectId,
            isDefaultProject:false,
            defaultProjectLinks:[ROUTES['projectlinks'][1]],
            project:null,
            allProjects:null,
            isSessionShare: false,
            analysisNav:false, //if we're on the analysis page,
            hideLinks:false, //state for hiding links for a collaborator,
            isCollaborator:false, //state for deciding if it's a collabrator or not
            access:null, //state to store access level for the collaborator
            ownerName: '',
            allowReadOnlyCollaborator: false,
            showReadOnlyLabel: false,
            showInviteCollaboratorModal: false,
            showScriptNudge: false,
            showUploadCallBanner: false,
            currentUser: auth.currentUser,
            isWorkspaceMember: false,
            showInviteCollaboratorButton: false,
            isWorkspaceEditor: false,
            showingBanner: false
        };
    },
    computed: {
      ...mapState([
        "showEmailVerificationBanner",
        "showUploadModal",
        "showCreateNewProjectModal",
        "checkScriptNudge",
        "userWorkspace",
        "userProfile",
        "migratingWorkspace",
        "workspace",
        "workspaceMigrationNames"
      ]),
      trialEnded() {
        return this.$store.state.billingDetails.trialEnded;
      },
      subscriptionActive(){
            return this.$store.state.billingDetails.subscriptionActive;
        },
      planEndDate(){
            return this.$store.state.billingDetails.planEndDate;
      },
      searchEnabled(){
        return this.$store.state.searchEnabled;
      }

    },
    components: { Link, StartRecording, ChangeCallMenu, AnnouncementBar, InviteCollaborator, Avatar,HelpButton, WorkspaceMenu, UpgradePlanTooltip, LoopColoredAvatar, MigratingWorkspaceLoaderModal },
    mounted(){
      this.checkBannerShown()
      this.checkUploadCallsNudge()
      if(this.userWorkspace.owner != auth.currentUser.uid){
        this.isWorkspaceMember = true;
      }
},
    methods:{
      checkBannerShown(){
        console.log('inside check banner shown: ',this.trialEnded, this.subscriptionActive, this.$route.name, this.showEmailVerification)
        if(
          (this.trialEnded && !this.subscriptionActive) ||
          (this.$route.name === "Dashboard" &&
            (!this.trialEnded || this.showEmailVerificationBanner)
          )
        ){
          this.showingBanner = true
        }else{
          this.showingBanner = false
        }
      },
      toggleUpgradePlanModal(){
          this.$store.dispatch('toggleUpgradePlanModal',true)
      },
      trackCallsNudge(){
        window.analytics.track("click on call tab nudge after script creation from discussion guide view", {
            email: auth.currentUser.email,
        });
      },
      async checkScriptCreationCallNudge(){
      const _projectId = this.$route.params.projectId
      const script = await QuestionScriptsAPI.getScriptByProjectId(_projectId);
      if(script == null){
        const scriptNudge = await UsersAPI.getNudgeCount('Scripts');
        if(scriptNudge.status==='success'){
          console.log(scriptNudge)
          const { scriptCount } = scriptNudge;
          if(scriptCount<=4) this.showScriptNudge = true;
          else this.showScriptNudge = false;
          this.$store.dispatch('toggleCheckScriptNudge',false)
        }
      }else{
        this.showScriptNudge = false;
        this.$store.dispatch('toggleCheckScriptNudge',false)
      }

      },
      async checkUploadCallsNudge(){
        const callsNudge = await UsersAPI.getNudgeCount('Uploaded Calls');
        if(callsNudge.status==='success'){
          const { uploadCount } = callsNudge;
          if(uploadCount<=5) this.showUploadCallBanner = true;
          else this.showUploadCallBanner = false;        
        }
      else{
        this.showUploadCallBanner = false;
      }

      },
      goBack(){
        
        if(this.editorNav) this.$router.push(`/projects/${this.projectId}/sessions`)
        else if(!this.globalNav) this.$router.push(`/projects`);
        else{
           if(this.$router.go(-1)) return;
           this.$router.push('/')
        }
      },

      async handleAnnoucementLinkClick(action){
        if(action == 'call'){
           // console.log("clicked on announcement link")
          window.analytics.track("clicked Upload a call on announcement bar from home view", {
              email: auth.currentUser.email,
          });
          this.$store.dispatch('toggleUploadModal',!this.showUploadModal)
        }
        else if(action == 'open-project-modal'){
          console.log("clicked on announcement link")
          this.$store.dispatch('toggleCreateNewProjectModal',!this.showCreateNewProjectModal)
        }
        else if(action == 'verify-email'){
            window.analytics.track("click on Resend Link on email verification announcement bar from home view", {
                email: auth.currentUser.email,
            });
            // const continueUrl ="https://staging-looppanel--pr249-team-v1-workspace-qk0yx8tx.web.app/?emailVerified=true"
            const continueUrl = 'https://app.looppanel.com/?emailVerified=true'
            // // const continueUrl = 'http://localhost:8080?emailVerified=true';
            
            let receiverEmail  = auth.currentUser.email;
            let emailType = "verify_email";
            const response = await TransactionalEmailAPI.triggerTransactionalEmail(receiverEmail, emailType, continueUrl);
            console.log("verify email", response)
            // Email verification sent!
            window.analytics.track("trigger Email verification email homepage banner", {
                email: auth.currentUser.email
            });
            alert("We've sent you a verification link on your email")

            // Disable firebase SDK based email verificatin
            // auth.currentUser.sendEmailVerification({
            //       url: continueUrl
            // })
            // .then(() => {
            //   // Email verification sent!
            //   window.analytics.track("trigger Email verification email homepage banner", {
            //       email: auth.currentUser.email
            //   });
            //   alert("We've sent you a verification link on your email")
            //   // ...
            // });
        } else if(action == 'upgrade-plan'){
          this.toggleUpgradePlanModal()
        }
      },

      async getProjectDetails(){
        const project=await ProjectsAPI.getSingleProjectById(this.projectId);
        console.log({project});
        // if(project.userId!=auth.currentUser.uid) this.hideLinks=true;
        // else this.hideLinks=false;
        // console.log('hide links',this.hideLinks)
        this.project=project;
        
        //Check for default project 
        if(project.default) this.isDefaultProject=true;
        else this.isDefaultProject=false;
        
        if(!this.invitationId && this.project.userId!=auth.currentUser.uid){
          console.log('This user does not own this project')
          //Make collaborator validator API Call here
          const validateCollaborator = await ProjectsAPI.validateCollaborator(this.projectId)
          console.log('validateCollaborator', validateCollaborator)
          const isAllowed = validateCollaborator.data.allowed;
          if(isAllowed){
            const access = validateCollaborator.data.data.access;
            this.access = access;
            if(this.access == 'read-all') this.allowReadOnlyCollaborator=true
            else{
               this.allowReadOnlyCollaborator=false
            }
            console.log('isAllowed',{isAllowed})
            console.log('access',access)
          //API returns true for a collaborator
           const owner = await UsersAPI.getUserName(this.project.userId)
           this.ownerName = owner.name
            this.isCollaborator = true;
            const collabData = {
              isCollaborator: true,
              access: this.access,
              ownerProjectId: this.projectId,
              ownerUserId: this.project.userId,
              ownerUserName : owner.name
            }
            this.$store.dispatch("collab", collabData);
          }
          else{
            this.isCollaborator = false;
            // this.$router.push('/')
          }
        }else{
          console.log('The user owns this project')
        }
      },
      async getAllProjects(){
        const allProjects=await ProjectsAPI.getAllWorkspaceProjects(this.userWorkspace.id);
        this.allProjects=allProjects;
        console.log('all projects',this.allProjects)
      },
      toggleInviteModal(){
        this.$refs.inviteCollaboratorRef.showInviteModal = true;
      },
      async checkInvitationStatus(){
      this.invitationId = this.$route.query.invitation;
      console.log("check invitation called ",this.invitationId,this.$route.query)
      //Invitation exists
      if(this.invitationId){
          console.log("invitation=>",this.invitationId)
          if(!auth.currentUser){
            const url = new URL(window.location.href)
            // console.log('Unauthenticated user accessing collaborative analysis page',url.pathname,this.$route)
            localStorage.setItem('redirect_url',JSON.stringify(this.$route.fullPath))
            this.$router.push('/')
          }else{
            // Make invitation accept API call here
            const acceptInvite = await ProjectsAPI.acceptOrRejectSharedProjectInvite(this.invitationId,this.projectId,'accepted')
            if(acceptInvite.status==='error') this.$router.push('/')
            window.analytics.track("accept collaboration invite from project calls page", {
                    email: auth.currentUser.email,
            });
            console.log({acceptInvite})
            if(acceptInvite.status=='success'){
              const queryParams = { ...this.$route.query };
              delete queryParams.invitation;
              this.$router.replace({ query: queryParams });
              this.invitationId=null;
              window.location.reload()
            }

          }
          

        }else{
          if(!auth.currentUser) this.$router.push('/')
          else{ 
            this.getProjectDetails()
            this.getAllProjects();

          }
          // else this.onMountFunctionCalls()
        }
    },
    },
    watch: {
    trialEnded: function(newVal,oldVal){
      if(newVal != oldVal) this.checkBannerShown()
    },
    subscriptionActive: function(newVal,oldVal){
      if(newVal != oldVal) this.checkBannerShown()
    },
    showEmailVerificationBanner: function(newVal,oldVal){
      console.log('Email verification banner',newVal,oldVal);
    },
    checkScriptNudge: function(newVal,oldVal){
      console.log('script nudge->',{newVal},{oldVal})
      if(newVal == true) this.checkScriptCreationCallNudge()
      else{
        this.$store.dispatch('toggleCheckScriptNudge',false)
      }
    },
    '$route': async function(to, from) {
       console.log('route=>',to,from)
       this.checkBannerShown()
       // if user is moving away from analysis page dispatch an event to update analysis active user cleanup ref: analysis/index.js
       if(from.name === 'Project Analysis' && to.name !== 'Project Analysis'){
        const projectId = from.params.projectId;
        this.$store.dispatch('removeActiveAnalysisUser',projectId)
       }

      if(to.name === 'settings') this.settingsNav=true;
      else this.settingsNav=false;
       
      if(to.name == 'VerifyEmail' || to.name == 'workspace' || to.name == 'workspaces'){
        this.showMinimalNavbar = true;
      }else{
        if(this.showMinimalNavbar) this.showMinimalNavbar = false;
      }
      if(to.name === 'Dashboard'){
        this.checkUploadCallsNudge()
      }
      if(to.name === 'search'){
        this.searchNav = true
      }else{
        this.searchNav = false
      }
      if(to.name==='editor'){
        this.editorNav=true;
        if(this.notetakingNav) this.notetakingNav=false;
      }else if(to.name==='Notetaking'){
        this.notetakingNav=true;
      } else if (to.name==='session_share'){
        this.isSessionShare = true
      } else if (to.name==='brewnote' || to.name==='brewnote editor'){
        this.brewNoteNav = true
      }
      else{
        this.isSessionShare = false;
        this.editorNav=false;
        if(this.notetakingNav) this.notetakingNav=false;

        //Check if you're on the analysis page
        // if(){
        //   this.analysisNav=true;
        // }
        
        if(
          (to.name!=='Projects' && to.name.includes('Project')) ){
            //Check if user has access to the project
            console.log('project route changed')
            if(to.name == 'Project Script'){
              // this.checkScriptNudge();
              const showInvitationModal = this.$route.query.showInvitationModal
              console.log('inside project script',showInvitationModal)
   
              if(showInvitationModal){
                console.log(showInvitationModal, ' is true ')
                // this.showInviteCollaboratorModal = true;
                this.$nextTick(() => {
                  console.log('refs',this.$refs)

                  //Auto open invite users modal only if trial/subscription is active
                  if(!(this.trialEnded && !this.subscriptionActive)){
                    this.toggleInviteModal()
                  }
                  const queryParams = { ...this.$route.query };
                  delete queryParams.showInvitationModal;
                  this.$router.replace({ query: queryParams });
                })
                // console.log('showInviteCollaboratorModal',this.showInviteCollaboratorModal)
              }
            }
            this.showScriptNudge = false;
            if(this.isCollaborator){
              if(to.name == "Project Sessions" || to.name  == "Project Script")
                this.showReadOnlyLabel = true;
              else if(to.name == 'Project Analysis' && this.access == 'read-all') this.showReadOnlyLabel = true;
              else this.showReadOnlyLabel = false;
            }
            console.log('lv 1 in project navigation');
            this.globalNav=false;
            this.projectId=this.$route.params.projectId;

            // Fetch the project details and check for the userId
            const projectDetails = await ProjectsAPI.getSingleProjectById(this.projectId);
            console.log('projectDetails:', projectDetails,auth.currentUser.uid)
            if(projectDetails && projectDetails.userId == auth.currentUser.uid){
               this.showInviteCollaboratorButton = true;
               this.isWorkspaceMember = false;
            }
            else{
              //check if user is workspace collaborator
                const isWorkspaceCollaborator = await WorkspaceAPI.validateWorkspaceAccess(this.userWorkspace.id)
                console.log('isWorkspaceCollaborator',{isWorkspaceCollaborator})

                if(isWorkspaceCollaborator.status == 'success' && isWorkspaceCollaborator.data.status && isWorkspaceCollaborator.data.status == 'success' && isWorkspaceCollaborator.data.allowed==true){
                  this.isWorkspaceMember = true;
                  if(this.userProfile.workspace_role && this.userProfile.workspace_role == 'editor'){
                    this.isWorkspaceEditor = true;
                  }
                  else{
                    this.isWorkspaceEditor = false
                  }
                  if(this.isWorkspaceMember && this.isWorkspaceEditor) this.showInviteCollaboratorButton = true;
                  else this.showInviteCollaboratorButton = false;
              }
            }

            //first check access here
            this.checkInvitationStatus();
            if(this.$store.state.defaultProject.id===this.projectId) this.isDefaultProject=true;
            else this.isDefaultProject=false;
            // this.getProjectDetails();
            // this.getAllProjects();
        }else{
            console.log("going to global nav")
            this.showScriptNudge = false;
            if(this.globalNav==false){
               this.globalNav=true;
               this.isCollaborator = false;
               this.showReadOnlyLabel = false;
               this.allowReadOnlyCollaborator = false;
            }
        }
      }

    }
  }
}
</script>

<style scoped>
    .nv-bar{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 0px 0px 40px;
      min-height: 64px !important;
      background: #FFFFFF;
    }

    .nv-left {
      display: flex;
      align-items: center;
      border-bottom: 0px solid #EDEDED;
      width: 600px;
    }

    .nv-right {
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 0px solid #EDEDED;
      min-width: 450px;
    }
    .nv-center{
      margin-left: auto;
      margin-right: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 0px solid #EDEDED;
    }

    @media screen and (max-width: 800px) {
      .nv-bar {
        flex-direction: column;
        padding: 12px 0px 0px 0px;
      }
      
      .nv-left{
        padding-bottom: 12px;
      }
      .nv-center{
        padding-bottom: 12px;
        width: 100%;
        justify-content: center;
      }
      .nv-right {
        border-bottom: 0px solid #EDEDED;
        border: 0px solid #EDEDED;
        margin-left: 0;
        width: 100%;
      }
    }

    .nav-container{
        position: fixed;
        width: 100%;
        z-index: 100;
    }
    .navbar{
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0px 0px 0px 40px;
        height: 64px !important;
        background: #FFFFFF;
        border-bottom: 0px solid #EDEDED;
    }

    .navbar .nav-outer,.nav-outer .nav-left,.nav-middle{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .nav-left {
      width: 370px;
    }

    .navbar .nav-outer{
        width: 1320px;
    }

    .nav-left, .nav-middle,.nav-right{
        /* flex: 0.33; */
        /* flex: 1; */
    }

    .nav-middle{
      margin-right: 300px;
    }

    /* .nav-right{
        width: 435px;
    } */

    .project-name-dropdown{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */
  display: flex;
  align-items: center;
  /* Neutrals/600 */
  color: #4C4C4C;
  padding: 8px 16px;
  /* Neutrals/100 */
  border-radius: 33px;
}

.project-name-dropdown:hover{
  padding: 8px 16px;
  /* Neutrals/100 */
  background: #F8F8F8;
  border-radius: 33px;
}
.nav-back-button{
  color: #666666;
  font-size: 21px;
  margin-right: 10px;
  cursor: pointer;
}

.notetaking-nav{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 40px;
  width: 1440px;
  height: 55px;
  /* Neutrals/White */
  background: #FFFFFF;
  /* Neutrals/200 */
  border-bottom: 1px solid #EDEDED;
}

.notetaking-nav span{
  margin-right: 10px;
}

.notetaking-nav div{
  display: flex;
  align-items: center;
}

.notetaking-nav p{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  text-transform: capitalize;

  /* Neutrals/500 */

  color: #666666;
  margin-bottom: 0px;
  margin-left: 5px;
}

/* For small screens, we can get rid of the looppanel logo home route to make some space for other elements */
@media only screen and (max-width: 960px) {
  .looppanel-logo{
    display: none !important;
  }
}

/* 
  Various breakpoints for adjusting the width of the navbar according to screen size.
  #TODO: Refactor these queries into simpler media queries
*/
@media only screen and (min-width:1355px) and (max-width: 1400px) {
  .navbar .nav-outer{
      width: 1280px;
  }
}

@media only screen and (min-width:1260px) and (max-width: 1350px) {
  .navbar .nav-outer{
      width: 1200px;
  }
}

@media only screen and (min-width:1181px) and (max-width: 1259px) {
  .navbar .nav-outer{
      width: 1130px;
  }
}

@media only screen and (min-width:1121px) and (max-width: 1180px) {
  .navbar .nav-outer{
      width: 1040px;
  }
}

@media only screen and (min-width:1061px) and (max-width: 1120px) {
  .navbar .nav-outer{
      width: 970px;
  }
}

@media only screen and (min-width:961px) and (max-width: 1060px) {
  .navbar .nav-outer{
      width: 900px;
  }
}

@media only screen and (min-width:891px) and (max-width: 960px) {
  .navbar .nav-outer{
      width: 900px;
  }
}

@media only screen and (min-width:800px) and (max-width: 890px) {
  .navbar .nav-outer{
      width: 800px;
  }
}


/* 
  For really small screen size (~tablet to phones), 
  we can get rid of the recording UI on the navbar 
  and instead display it on the page 
*/
@media only screen and (max-width: 799px) {
  .navbar .nav-outer{
      width: 700px;
  }

  /* This hides the right side of the navbar completely */
  .nav-right{
    display: none;
  }
}

.invita-collab-btn-container{
  margin-left: 25%;
  display: flex;
}

.avatar-container{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 15%;
}

.view-only-label{
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 20px;
  color:#999999
}

.migrating-workspace-container{
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  background: #fff;
  z-index: 1000;
  opacity: 0.5;
}


</style>